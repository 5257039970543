import React from 'react';
import { PropTypes } from 'prop-types';

import './MapImage.scss';

function MapImage({ image, isSmall , location}) {

  if (!image) {
    return null;
  }

  const shouldLink = location.latitude && location.longitude && location.zoomLevel;
  // TODO: Need to test this on a real phone to move forward.
  // https://www.google.com/maps/@?api=1&map_action=map&center=46.553345,11.762763&zoom=14
  return (
    shouldLink ?
    <a
    href={`https://www.google.com/maps/@?api=1&map_action=map&center=${location.latitude +','+ location.longitude}&zoom=${location.zoomLevel}`}
    className=""
    >
      <div
        className={`mapimage__list ${isSmall ? 'is-small' : ''}`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="app-content">
          <div className="mapimage__open-in-map-icon"></div>
        </div>
      </div>
    </a>
    : (      
    <div
      className={`mapimage__list ${isSmall ? 'is-small' : ''}`}
      style={{ backgroundImage: `url(${image})` }}
    />
    )
  );
}

MapImage.propTypes = {
  image: PropTypes.string,
  isSmall: PropTypes.bool,
};

export default MapImage;
