import React, { Fragment } from "react";
import { PropTypes } from 'prop-types';

const NewlineToBr = ({ text }) => {
    return (
        <>
            {text.split("\n").map(
                (item, key) => (
                    <Fragment key={key}>
                        {item}
                        <br />
                    </Fragment>
                )
            )}
        </>
    );
};

NewlineToBr.propTypes = { text: PropTypes.string };

export default NewlineToBr;
