import React from 'react';

import './Loader.scss';

function LoaderComponent() {
  return (
    <div className="Loader-content">
      <div className="lds-dual-ring"></div>
    </div>
  );
}

LoaderComponent.propTypes = {};

export default LoaderComponent;
