import { Store, set, get, clear } from 'idb-keyval';

const customStore = new Store('Alpresor', 'pwa');

const getOfflineTimeKey = (key) => `${key}-time`;

export const clearOfflineData = () => {
  clear(customStore);
};

export const loadOfflineData = async (offlineKey) => {
  const offlineData = await get(offlineKey, customStore);

  if (offlineData) {
    return offlineData;
  }

  return null;
};

export const saveOfflineData = (id, result) => {
  set(id, result, customStore);
  set(getOfflineTimeKey(id), new Date(), customStore);
};

const offlineSaveHours = {
  trip: 8,
  weather: 8,
  location: 168, // 7 days
  globals: 168, // 7 days
  notifications: 1,
};

export const shouldUpdateOfflineData = async (offlineKey, dataId) => {
  // No key to check
  if (offlineKey) {
    return true;
  }

  // Do not update saved data if no internet - no new data will arrive
  if (!navigator.onLine) {
    return false;
  }

  const offlineData = await loadOfflineData(offlineKey);

  // No saved data
  if (!offlineData) {
    return true;
  }

  const offlineId = offlineData.id;

  // Saved data is something else than requested, unless requested globals
  if (offlineId !== dataId && offlineKey !== 'globals') {
    return true;
  }

  const cacheTimeInHours = offlineSaveHours[offlineKey];
  const offlineTime = await loadOfflineData(getOfflineTimeKey(offlineKey));
  const todayTime = new Date();
  const fetchNewTime = new Date(offlineTime);
  fetchNewTime.setHours(fetchNewTime.getHours() + cacheTimeInHours);

  if (fetchNewTime < todayTime) {
    return true;
  }

  return false;

  // // FOR TRIP ONLY
  // const extendedFreezeFrameInDays = 4; // In days
  // const date = new Date();
  // date.setDate(date.getDate() + extendedTimeFrame);

  // // Update data if today is before freeze time
  // if (date < new Date(data.startDate)) {
  //   clear();
  //   return true;
  // }
  // // Remove data if today is after freeze time
  // if (new Date(data.endDate) < date) {
  //   clear();
  //   return;
  // }
};
