import React from 'react';
import { Redirect } from 'react-router-dom';

import './Home.scss';

import { setIdFromOffline } from 'helpers/fetchHelper';
import LoaderComponent from 'components/loader/Loader';

export default function Home({
  trip,
  tripId,
  setTripId,
  location,
  locationId,
  setLocationId,
}) {
  if (!trip && !tripId) {
    setIdFromOffline('trip', setTripId);
  }

  if (!location && !locationId) {
    setIdFromOffline('location', setLocationId);
  }

  if (tripId) {
    return <Redirect to={`/trip/${tripId}`} />;
  } else if (locationId) {
    return <Redirect to={`/location/${locationId}`} />;
  } else if (typeof tripId !== 'boolean' && typeof locationId !== 'boolean') {
    return <Redirect to={`/login`} />;
  }

  return <LoaderComponent />;
}
