import { getMarket } from 'helpers/marketHelper';
import React from 'react';

function LocationSelector() {
  const setLocation = (e) => {
    window.location.replace(`/location/${e.target.value}`);
  };

  const marketId = getMarket();
  const destinationString =
    marketId === 'no'
      ? 'Velg destinasjon'
      : marketId === 'fi'
      ? 'Valitse kohde'
      : 'Välj destination';

  const austria =
    marketId === 'no'
      ? 'Østerrike'
      : marketId === 'fi'
      ? 'Itävalta'
      : 'Österrike';
  const italy =
    marketId === 'no' ? 'Italia' : marketId === 'fi' ? 'Italia' : 'Italien';
  const france =
    marketId === 'no'
      ? 'Frankrike'
      : marketId === 'fi'
      ? 'Ranska'
      : 'Frankrike';

  return (
    <div style={{ margin: '20px 0px', border: '1px solid #ebebeb' }}>
      <select
        onChange={setLocation}
        className="login__input"
        style={{
          border: 0,
          padding: '10px',
          fontWeight: 'bold',
          borderRight: '8px solid transparent',
          width: '100%',
        }}
      >
        <option>{destinationString}</option>
        {/*}
                <optgroup label="Vinter"></optgroup>
                */}
        <optgroup label={austria}>
          <option value={'bad_w'}>&nbsp;&nbsp;Bad Gastein</option>
          <option value={'isg_w'}>&nbsp;&nbsp;Ischgl</option>
          <option value={'lec_w'}>&nbsp;&nbsp;Lech-Zürs</option>
          <option value={'saa_w'}>&nbsp;&nbsp;Saalbach</option>
          <option value={'sla_w'}>&nbsp;&nbsp;Schladming</option>
          <option value={'ant_w'}>&nbsp;&nbsp;St Anton</option>
          <option value={'zel_w'}>&nbsp;&nbsp;Zell am See</option>
        </optgroup>
        <optgroup label={italy}>
          <option value={'can_w'}>&nbsp;&nbsp;Canazei</option>
          <option value={'cer_w'}>&nbsp;&nbsp;Cervinia</option>
          <option value={'luc_w'}>&nbsp;&nbsp;Champoluc</option>
          {/* <option value={'mad_w'}>&nbsp;&nbsp;Madonna di Campiglio</option> */}
          <option value={'sau_w'}>&nbsp;&nbsp;Sauze d’Oulx</option>
          <option value={'val_w'}>&nbsp;&nbsp;Val Gardena</option>
        </optgroup>
        <optgroup label={france}>
          <option value={'tho_w'}>&nbsp;&nbsp;Val Thorens</option>
        </optgroup>
        {/*
                <optgroup label="Sommar">
                    <option value={'bad_s'}>Bad Gastein</option>
                    <option value={'cer_s'}>Cervinia</option>
                </optgroup>
                }
            
        <option value={'bad_s'}>Bad Gastein</option>
        <option value={'cer_s'}>Cervinia</option>

        {/*
                <optgroup label="Sommar">
                    <option value={'bad_s'}>Bad Gastein</option>
                    <option value={'cer_s'}>Cervinia</option>
                </optgroup>
                */}
      </select>
    </div>
  );
}

export default LocationSelector;
