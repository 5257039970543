import React from 'react';
import { PropTypes } from 'prop-types';
import './tripFramework.scss';

import TripHero from './tripHero';

import SecondaryNavigation from 'components/secondaryNavigation/SecondaryNavigation';

function TripFramework({
  tripId,
  trip,
  translations,
  lastLevelPath,
  children,
  hideCTAMobile,
  hideHero
}) {
  if (!tripId || !trip || !translations) {
    // TODO: Return Error page?
    return null;
  }

  const subpages = [
    {
      name: translations.tabs.booking,
      url: `/trip/${tripId}`,
      isActive: lastLevelPath !== 'addons' && lastLevelPath !== 'faq'  && lastLevelPath !== 'notifications',
    },
    {
      name: translations.tabs.faq,
      url: `/trip/${tripId}/faq`,
      isActive: lastLevelPath === 'faq',
    },
  ];
  const tripHasPassed = new Date() > new Date(trip.endDate);
  if (!tripHasPassed) {
    subpages.splice(1,0,
      {
        name: translations.tabs.addons,
        url: `/trip/${tripId}/addons`,
        isActive: lastLevelPath === 'addons',
      },)
    }

  const heroTranslations = translations.hero;

  return (
    <div className="trip-content">
      {lastLevelPath !== 'notifications' &&
        <SecondaryNavigation options={subpages} />
      }
      {!hideHero && <TripHero
        title={heroTranslations.title}
        background={trip.image}
        message={tripHasPassed ? heroTranslations.afterTripMessage : heroTranslations.message}
        user={trip.contact.firstName}
        location={trip.location.name}
        duration={trip.durationDate}
        cta={trip.cta}
        isPaid={trip.isPaid || trip.isCancelled}
        hideCTAMobile={hideCTAMobile}
        greeting={heroTranslations.greeting}
        startDate={trip.startDate}
        daysLeft={heroTranslations.daysLeft}
      />}

      {children}

      {trip.information && (
        <div className="trip__information">
          <div className="app-content trip__row">
            <div className="trip__information-column">
              <div className="trip__information-title">
                {trip.information.column1.title}
              </div>
              <div className="trip__information-cta">
                <a
                  className="trip__information-link"
                  href={trip.information.column1.cta.url}
                >
                  {trip.information.column1.cta.text}
                </a>
              </div>
            </div>
            <div className="trip__information-column">
              <div className="trip__information-title">
                {trip.information.column2.title}
              </div>
              <div className="trip__information-cta">
                <a
                  className="trip__information-link"
                  href={trip.information.column2.cta.url}
                >
                  {trip.information.column2.cta.text}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

TripFramework.propTypes = {
  lastLevelPath: PropTypes.string,
  tripId: PropTypes.string,
  translations: PropTypes.object,
};

export default TripFramework;
