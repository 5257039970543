import React from 'react';

import { PropTypes } from 'prop-types';
import './tripDestination.scss';

function TripDestination({ tripDestination, translationsDestination }) {
  const rows = Object.keys(tripDestination).map((key) => {
    let value = tripDestination[key];
    let label = translationsDestination[key];
    let matches = null;

    if ((matches = key.match(/\d+$/))) {
      const keyNumber = matches[0];
      const keyString = key.replace(keyNumber, '');

      value = tripDestination[key];
      label = `${translationsDestination[keyString]} ${keyNumber}`;
    }

    return (
      <div className="trip__destination-row" key={key}>
        <div className="trip__destination-label">
          {label}
        </div>
        <div className="trip__destination-spacer"></div>
        <div className="trip__destination-value">{value}</div>
      </div>
    );
  });

  return (
    <div className="trip__destination">
      <div className="trip__destination-content">
        {rows}
        <div className="trip__destination-row trip__destination-link" onClick={() => { window.scroll({top: 9999}) }}>
          <div className="trip__destination-label">
            {translationsDestination.linkLabel}
          </div>
          <div className="trip__destination-spacer"></div>
          <div className="trip__destination-value trip__destination-link-text">{translationsDestination.linkText}</div>
        </div>  
      </div>
    </div>
  );
}

TripDestination.propTypes = {
  tripDestination: PropTypes.object,
  translationsDestination: PropTypes.object,
};

export default TripDestination;
