import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './Activity.scss';

import ActivityList from './shared/activityList';
import ActivityIndividual from './shared/activityIndividual';
import ErrorComponent from 'components/error/Error';
import LoaderComponent from 'components/loader/Loader';

function Activity({ setTripId, trip, translations, activities, locationId }) {
  let { id } = useParams();
  //let activities = (trip && trip.activities) || {};
  const tripId = id;
  const activityTranslations = translations && translations.activity;

  if (!trip && id !== 'null') {
    setTripId && setTripId(String(tripId));
    return <LoaderComponent />;
  }

  
  else if (activities && !Object.keys(activities).length) {

    return <ErrorComponent useLogo={false} source="Activity" />;
  }

  return (
    <Switch>
      <Route exact path="/activity/:id">
        <ActivityList
          activities={activities}
          translations={activityTranslations}
          tripId={tripId}
          trip={trip}
          locationId={locationId}
        />
      </Route>
      <Route path="/activity/:id/:activity">
        <ActivityIndividual
          parentPage={`/activity/${tripId}`}
          activities={activities}
          translations={activityTranslations}
          tripId={tripId}
          trip={trip}
          locationId={locationId}
        />
      </Route>
    </Switch>
  );
}

Activity.propTypes = {
  translations: PropTypes.object,
  location: PropTypes.object,
  setTripId: PropTypes.func,
  trip: PropTypes.object,
};

export default Activity;
