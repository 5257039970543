import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './locationContentPage.scss';

import HtmlText from 'components/htmlText/HtmlText';

function LocationContentPage({
  parentPath,
  parentName,
  page,
  locationId,
  translations,
}) {
  if (!page) {
    return null;
  }
  const parentPage = parentPath.replace(':id', locationId);

  const getSubRatingName = (originalName) => {

    const name = originalName.split("_")[1];

    if (translations.tripAdvisor[`subrating${name}`] !== undefined) {
      return translations.tripAdvisor[`subrating${name}`];
    }

    return name;
  };

  return (
    <div>
      <div
        className="location-c__hero"
        style={{ backgroundImage: `url(${page.content.image})` }}
      >
        <div className="app-content no-padding location-c__hero-content">
          <h1 className="location-c__hero-title">{page.name}</h1>
          <div className="location-c__hero-subtitle">{page.content.title}</div>
        </div>
      </div>
      <div className="app-content">
        <div className="location-c__back">
          <Link to={parentPage} className="location-c__back-link">
            {translations.backLink}
          </Link>
        </div>
        <div className="location-c__content">
          <HtmlText markup={page.content.text} />
        </div>
        {page.content.address && (
          <div className="location-c__content location-c__address">
            <div className="location-c__address-line">
              {page.content.address}
            </div>
            <div className="location-c__directions-container">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  page.content.googleMapSpecificAddress ? page.content.googleMapSpecificAddress : page.content.address
                )}`}
                className="location-c__directions"
              >
                {translations.openInMaps}
              </a>
            </div>
          </div>
        )}
        {page.content.web && page.content.web.url && (
          <div className="location-c__content">
            <a className="location-c__web" href={page.content.web.url}>
              {page.content.web.text || page.content.web.url}
            </a>
          </div>
        )}
        {page.content.tripAdvisorInfo &&
        <div className="location-c__content">
          <div className="location-c__tripAdvisorRating">
            <div className="location-c__tripAdvisorRatingHeading">
              TRIPADVISOR
            </div>
            <div className="location-c__tripAdvisorRatingInfo">
              <img alt="TripAdvisor" src={`https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${page.content.tripAdvisorInfo.rating % 1 === 0 ? `${page.content.tripAdvisorInfo.rating}.0` : page.content.tripAdvisorInfo.rating }-MCID-5.svg`} /><br />
              {page.content.tripAdvisorInfo.numberOfReviews} {translations.tripAdvisor.numRatings}
            </div>

          </div>
          <div className="location-c__tripAdvisorColumns">
          <div className="location-c__tripAdvisor">
            <h4>{translations.tripAdvisor.memberHeader}</h4>
            <dl>
                <dt>{translations.tripAdvisor.five}</dt>
                <dd className="rating-item">
                    <div className="rating-item__score-container">
                        <div className="rating-item__bar">
                            <div className="rating-item__bar-value" style={{width: `${page.content.tripAdvisorInfo.reviewRatingCount.five / page.content.tripAdvisorInfo.numberOfReviews * 100}%`}}></div>
                        </div>
                        <div className="rating-item__score">{page.content.tripAdvisorInfo.reviewRatingCount.five}</div>
                    </div>
                </dd>
                <dt>{translations.tripAdvisor.four}</dt>
                <dd className="rating-item">
                    <div className="rating-item__score-container">
                        <div className="rating-item__bar">
                            <div className="rating-item__bar-value" style={{width: `${page.content.tripAdvisorInfo.reviewRatingCount.four / page.content.tripAdvisorInfo.numberOfReviews * 100}%`}}></div>
                        </div>
                        <div className="rating-item__score">{page.content.tripAdvisorInfo.reviewRatingCount.four}</div>
                    </div>
                </dd>
                <dt>{translations.tripAdvisor.three}</dt>
                <dd className="rating-item">
                    <div className="rating-item__score-container">
                        <div className="rating-item__bar">
                            <div className="rating-item__bar-value" style={{width: `${page.content.tripAdvisorInfo.reviewRatingCount.three / page.content.tripAdvisorInfo.numberOfReviews * 100}%`}}></div>
                        </div>
                        <div className="rating-item__score">{page.content.tripAdvisorInfo.reviewRatingCount.three}</div>
                    </div>
                </dd>
                <dt>{translations.tripAdvisor.two}</dt>
                <dd className="rating-item">
                    <div className="rating-item__score-container">
                        <div className="rating-item__bar">
                            <div className="rating-item__bar-value" style={{width: `${page.content.tripAdvisorInfo.reviewRatingCount.two / page.content.tripAdvisorInfo.numberOfReviews * 100}%`}}></div>
                        </div>
                        <div className="rating-item__score">{page.content.tripAdvisorInfo.reviewRatingCount.two}</div>
                    </div>
                </dd>
                <dt>{translations.tripAdvisor.one}</dt>
                <dd className="rating-item">
                    <div className="rating-item__score-container">
                        <div className="rating-item__bar">
                            <div className="rating-item__bar-value" style={{width: `${page.content.tripAdvisorInfo.reviewRatingCount.one / page.content.tripAdvisorInfo.numberOfReviews * 100}%`}}></div>
                        </div>
                        <div className="rating-item__score">{page.content.tripAdvisorInfo.reviewRatingCount.one}</div>
                    </div>
                </dd>
            </dl>
          </div>
          <div className="location-c__tripAdvisor">
            <h4>{translations.tripAdvisor.overviewHeader}</h4>
            <dl>
            {page.content.tripAdvisorInfo.subRatings.map(item => (
              <>
                <dt>{getSubRatingName(item.name)}</dt>
                <dd title={item.value}><img alt={item.localizedName} src={`https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s${item.value % 1 === 0 ? `${item.value}.0` : item.value }-MCID-5.svg`} /></dd>
              </>
            ))}
            </dl>
          </div>
          </div>
        </div>}
      </div>
    </div>
  );
}

LocationContentPage.propTypes = {
  page: PropTypes.object,
  parentPath: PropTypes.string,
  parentName: PropTypes.string,
  translations: PropTypes.object,
  locationId: PropTypes.string,
};

export default LocationContentPage;
