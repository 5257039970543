import React from 'react';
import { PropTypes } from 'prop-types';

import './HtmlText.scss';

function HtmlText({ markup }) {
  return <div dangerouslySetInnerHTML={{ __html: markup }} />; 
}

HtmlText.propTypes = { markup: PropTypes.string };

export default HtmlText;
