const {fi} = require('./translations.fi.js');
const {no} = require('./translations.no.js');
const {se} = require('./translations.sv.js');

export const translate = (locale, key) => {

    let translations, str = null;
    switch (locale.toLowerCase() ) {
        case 'sv':
            translations = se;
            break;
        case 'fi':
            translations = fi;
            break;
        case 'no':
            translations = no;
            break;

        default:
            str = `No locale ${locale}`;            
    }

    if (translations){
        return key.split('.').reduce((p,c)=>p&&p[c]||null, translations) || `No translation ${locale} ${key}`;
    }
    else{ 
        return str
    }

}