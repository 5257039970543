import React from 'react';
import { PropTypes } from 'prop-types';

import './hikingLifts.scss';
import LinkList from 'components/linkList/LinkList';
import MapImage from 'components/mapImage/MapImage';

function HikingLifts({ locationLifts, globalTranslations}) {
  if (!locationLifts) {
    return null;
  }

  return (
    <div>
      <MapImage image={locationLifts.image} />
      <div className="app-content no-padding">
        <LinkList links={locationLifts.links} externalTranslation={globalTranslations.external}/>
      </div>
    </div>
  );
}

HikingLifts.propTypes = {
  globalTranslations: PropTypes.object,
  locationLifts: PropTypes.object,
};

export default HikingLifts;
