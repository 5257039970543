import React, { useState } from 'react';

import './Login.scss';

import { getMarket, getLogo } from 'helpers/marketHelper';
import { fetchLogin } from 'helpers/fetchHelper';
import LoaderComponent from 'components/loader/Loader';
import LocationSelector from 'components/locationSelector/LocationSelector';

export default function Login({ translations }) {
  const [booking, setBooking] = useState({
    email: '',
    bookingRef: '',
  });
  const [hasError, setError] = useState(false);
  const marketId = getMarket();
  const logo = getLogo(marketId);
  const destinationString =  marketId === "no" ? "Velg destinasjon" : marketId === "fi" ? "Valitse kohde" : "Eller välj destination";

  if (!translations) {
    return <LoaderComponent />;
  }

  const loginTranslations = translations.global.login || {};
  const formTranslations = loginTranslations.form || {};

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    booking[name] = value;
    setBooking(booking);
  };

  const submitLogin = (e) => {
    e.preventDefault();

    fetchLogin(booking).then((data) => {
      if (!data.reservationId) {
        setError(true);
        return;
      }

      window.location.replace(`/trip/${data.reservationId}`);
    });
  };

  return (
    <main className="login-content">
      <img src={logo} className="login-logo" alt="logo" />

      <div className="app-content">
        <form className="login__form" onSubmit={submitLogin}>
          <label className="login__label">
            <span>{formTranslations.bookingRefLabel} *</span>
            <input
              required
              className="login__input login__i-booking"
              type="text"
              pattern="[0-9]*"
              placeholder={formTranslations.bookingRefPlaceholder}
              name="bookingRef"
              onChange={handleChange}
            />
          </label>
          <label className="login__label">
            <span>{formTranslations.emailLabel} *</span>
            <input
              required
              className="login__input login__i-email"
              type="email"
              placeholder={formTranslations.emailPlaceholder}
              name="email"
              onChange={handleChange}
            />
          </label>
          {hasError && (
            <div className="login__error">{formTranslations.errorMessage}</div>
          )}
          <button className="login__input login__i-submit" type="submit">
            {formTranslations.submitLabel}
          </button>
        </form>
      </div>
      <hr />
      <div className="app-content">

        <div className="login__form" >
      <label className="login__label">
        <div className='login__separator'>
          <span>{destinationString}</span>
        </div>
        <LocationSelector />
      </label>
      </div>
    </div>
    </main >
  );
}
