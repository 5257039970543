import React from 'react';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './Hiking.scss';
import HikingFramework from './shared/hikingFramework';
import HikingLifts from './shared/hikingLifts';
/*import HikingWeather from './shared/hikingWeather';*/
import HikingWebcams from './shared/hikingWebcams';
import SingleWebcam from '../skiing/shared/skiingWebcam';
import ErrorComponent from 'components/error/Error';
import LoaderComponent from 'components/loader/Loader';

function Hiking({
  setLocationId,
  translations,
  location,
  trip,
  weather,
  setTripId,
  tripId,
}) {
  let { id } = useParams();
  const routerLocation = useLocation();
  const routerList = routerLocation.pathname.split('/');
  const lastLevelPath = routerList[routerList.length - 1];
  const secondLastLevelPath = routerList[routerList.length - 2];
  const locationId = id;
  const excursionsTranslations = translations && translations.excursions;

  if (!location) {
    setLocationId && setLocationId(String(locationId));

    if (!tripId) {
      trip && setTripId && setTripId(String(trip.id));
    }

    return <LoaderComponent />;
  }

  if (!excursionsTranslations || !location || !Object.keys(location).length) {
    return <ErrorComponent useLogo={false} source="Hiking" />;
  }

  return (
    <div className="hiking-content">
      <Switch>
        <Route exact path="/hiking/:id">
          <HikingFramework
            translations={excursionsTranslations.hiking}
            lastLevelPath={lastLevelPath}
            locationId={locationId}
            location={location}
          >
            <HikingLifts locationLifts={location.lifts} globalTranslations={translations.global}/>
          </HikingFramework>
        </Route>
        {/* 
        
        <Route exact path="/hiking/:id/weather">
          <HikingFramework
            translations={excursionsTranslations.hiking}
            lastLevelPath={lastLevelPath}
            locationId={locationId}
            location={location}
          >
            <HikingWeather
              translations={excursionsTranslations}
              locationWeather={weather}
              isSummer={trip && !!trip.isSummer}
            />
          </HikingFramework>
        </Route>

        */}
        <Route exact path="/hiking/:id/webcam">
          <HikingFramework
            translations={excursionsTranslations.hiking}
            lastLevelPath={lastLevelPath}
            locationId={locationId}
            location={location}
          >
            <HikingWebcams
              translations={excursionsTranslations.skiing}
              locationWebcams={location.webcams}
              htmlWebcams={location.htmlWebcams}
            />
          </HikingFramework>
        </Route>
        <Route exact path="/hiking/:id/webcam/:camId">
          <HikingFramework
           translations={excursionsTranslations.hiking}
           lastLevelPath={lastLevelPath}
           secondLastLevelPath={secondLastLevelPath}
           locationId={locationId}
           location={location}
          >
            <SingleWebcam
              translations={translations}
              locationWebcams={location.webcams}
              htmlWebcams={location.htmlWebcams}
            />
          </HikingFramework>
        </Route>
      </Switch>
    </div>
  );
}

Hiking.propTypes = {
  setLocationId: PropTypes.func,
  setTripId: PropTypes.func,
  trip: PropTypes.object,
  translations: PropTypes.object,
  location: PropTypes.object,
  fetchedLocation: PropTypes.bool,
};

export default Hiking;
