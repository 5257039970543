import React from 'react';
import './tripAfterInfo.scss';

const TripAfterInfo = ({
    imageUrl,
    mobileImageUrl,
    imageAlternativeText,
    caption,
    content,
    url
 }) => {
    const className = 'TripAfterInfo';

    const contentClasses = [`${className}__content`];

    if (content && !!content.horizontalPosition) {
        contentClasses.push(`${className}__content--horizontal-${content.horizontalPosition.toLowerCase()}`)
    }

    if (content && !!content.verticalPosition) {
        contentClasses.push(`${className}__content--vertical-${content.verticalPosition.toLowerCase()}`)
    }

    const buttonClasses = ['btn', `${className}__cta`];

    if (content && !!content.ctaStyle) {
        buttonClasses.push(`${className}__cta${content.ctaStyle.toLowerCase()}`);
    }

    if (content && !!content.ctaPositioning) {
        buttonClasses.push(`${className}__cta--pos-${content.ctaPositioning.toLowerCase()}`);
    }

    return (
        <div className={className}>
            <picture className={`${className}__image`}>
                <source
                    media="(min-width: 1200px)"
                    srcSet={`${imageUrl} 1x`}
                />
                <source
                    media="(max-width: 1200px)"
                    srcSet={`${mobileImageUrl} 1x`}
                />
                <img
                    src={mobileImageUrl}
                    alt={imageAlternativeText}
                />
            </picture>
            <div className={contentClasses.join(' ')}>

                {caption && <h1 className={`${className}__title`}>{caption}</h1>}
                {content && content.content &&
                    <div
                        className={`${className}__text`}
                        dangerouslySetInnerHTML={{ __html: content.content }}
                    />
                }
                {content && content.ctaText && url &&
                    <a href={url} className={` ${buttonClasses.join(' ')}`}>
                        {content.ctaText}
                    </a>
                }
            </div>
        </div>
    );
};

export default TripAfterInfo;