import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './locationRoutes.scss';
import { PAGE_TYPES } from 'shared/constants';

import LocationContentPage from './locationContentPage';
import LocationListPage from './locationListPage';

function LocationRoutes({
  parentPath,
  locationLinks,
  locationId,
  translations,
  locationName
}) {
  if (!locationLinks) {
    return null;
  }

  const allLinks = helperListBuilder(
    locationLinks,
    parentPath,
    translations.rootPage
  );

  return (
    <Switch>
      {allLinks.map((l) => {
        const props = {
          page: l,
          currentPath: `${l.parentPath}/${l.url}`,
          parentPath: l.parentPath,
          parentName: l.parentName,
          locationId: locationId,
          translations: translations,
        };

        if (l.type === PAGE_TYPES.listPage) {
          return (
            <Route exact path={`${l.parentPath}/${l.url}`} key={l.url}>
              <LocationListPage {...props} />
            </Route>
          );
        } else if (l.type === PAGE_TYPES.contentPage) {
          return (
            <Route exact path={`${l.parentPath}/${l.url}`} key={l.url}>
              <LocationContentPage {...props} />
            </Route>
          );
        }

        return null;
      })}
    </Switch>
  );
}

const helperListBuilder = (links, parentPath, parentName, allLinks = []) => {
  links.forEach((l) => {
    const newParent = `${parentPath}/${l.url}`;
    l.parentPath = parentPath;
    l.parentName = parentName;

    allLinks.push(l);

    if (l.links) {
      helperListBuilder(l.links, newParent, l.name, allLinks);
    }
  });

  return allLinks;
};

LocationRoutes.propTypes = {
  translations: PropTypes.object,
  locationLinks: PropTypes.array,
  locationId: PropTypes.string,
  parentPath: PropTypes.string,
};

export default LocationRoutes;
