import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import './Notification.scss';
import NotificationMessage from './NotificationMessage';

import { getLocalStorage, setLocalStorage } from 'helpers/uiHelper';
import { NOTIFICATION_ACCEPTED } from 'shared/constants';

function Notification({ notifications, tripId }) {
  const [showNotice, setShowNotice] = useState(null);
  let acceptedNotifications = getLocalStorage(NOTIFICATION_ACCEPTED) || '';
  const notices = notifications && notifications.notifications;

  const fetchAccepted = setInterval(() => {
    acceptedNotifications = getLocalStorage(NOTIFICATION_ACCEPTED) || '';
    if (showNotice && acceptedNotifications.includes(showNotice.id)) {
      setShowNotice(null);
    }
  }, 1500);

  useEffect(() => {

    return () => {
      window.clearInterval(fetchAccepted);
    }
  });

  useEffect(() => {
    if (!!notices) {
      notices.forEach((n) => {
        if (showNotice) {
          return;
        }

        if (!acceptedNotifications) {
          setShowNotice(n);
          return;
        }

        if (!acceptedNotifications.includes(n.id)) {
          setShowNotice(n);
          return;
        }
      });
    }
  }, [notices, acceptedNotifications, showNotice]);

  if (!notices || !notices.length) {
    return null;
  }

  const onClose = (id) => {
    setLocalStorage(NOTIFICATION_ACCEPTED, `${acceptedNotifications};${id}`);
    setShowNotice(null);
  };


  // All notifications have already been shown to the user
  if (!showNotice) {
    return null;
  }

  return (
    <div className="notification">
      <div className="app-content notification__content">
        <div>
          <NotificationMessage tripId={tripId} notification={showNotice} />
        </div>
        <div
          className="notifiction__close"
          onClick={() => onClose(showNotice.id)}
          aria-label="Close"
        ></div>
      </div>
    </div>
  );
}

Notification.propTypes = {
  notifications: PropTypes.object,
  tripId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Notification;
