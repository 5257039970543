import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './scheduleActivity.scss';

function ScheduleActivity({ translations, activity, tripId, trip, locationId }) {
  if (!translations || !activity) {
    return null;
  }

  const isAfterDeadline = () => {
    if (activity.deadlineDate) {
      const deadline = new Date(activity.deadlineDate);
      const now = new Date();

      if (now > deadline) {
        return true;
      }
    }
    return false;
  }

  const isInBookingRange = () => {

    if (!trip) {
      return false;
    }
    else{

      if (trip.location.id !== locationId) {
        return false;
      }
      else {
        const start = new Date(trip.startDate), end = new Date(trip.endDate);

        if (activity.datetime > start && activity.datetime < end){
          return true;
        }
        else{
          return false;
        }
      }
    }

  }

  return (
    <Link
      to={`/activity/${tripId}/${activity.id}`}
      className="activity__schedule-item app-content"
    >
      <div className="activity__schedule-item-inner">
        <div className="activity__schedule-info">
          <div className="activity__schedule-title">{activity.title}</div>
          <div className="activity__schedule-time">
          {activity.newTime || activity.time}
          </div>
        </div>
        {tripId !== 'null' && 
        !isAfterDeadline() && isInBookingRange() &&  activity.isBookable && (
          <div className="activity__schedule-booking">
            {translations.bookPlace}
          </div>
        )}
        {activity.canceled && (
          <div className="activity__schedule-booking activity__schedule-booking--canceled">
            {translations.canceled}
          </div>
        )}
      </div>
      {false && activity.usps &&
        <ul className="activity__usps">
          {activity.usps.map((x) => (
            <li key={x} className="activity__usp">
              {x}
            </li>
          ))}
        </ul>
      }
    </Link>
  );
}

ScheduleActivity.propTypes = {
  translations: PropTypes.object,
  activity: PropTypes.object,
  tripId: PropTypes.string,
};

export default ScheduleActivity;
