import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './LinkList.scss';

function LinkList({ links, internalLinks, externalTranslation }) {
  if (!links) {
    return null;
  }

  return (
    <div className="linklist__list">
      {links.map((l) => {
        if (internalLinks) {
          return (
            <div className="linklist__wrapper" key={l.text}>
              <Link className="linklist__link" to={l.url}>
                {l.text}
              </Link>
            </div>
          );
        }

        return (
          <div className="linklist__wrapper" key={l.text}>
            <a className="linklist__link" href={l.url}>
              {l.text}
              {externalTranslation && (
                <div className="linklist__link-external">
                  {externalTranslation}
                </div>
              )}              
            </a>
            
          </div>
        );
      })}
    </div>
  );
}

LinkList.propTypes = {
  links: PropTypes.array,
  internalLinks: PropTypes.bool,
};

export default LinkList;
