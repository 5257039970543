import React from 'react';
import { PropTypes } from 'prop-types';
import { updateUrlParameter } from 'helpers/urlHelper.js'; 

import './TripAddons.scss';

function TripAddons({ translations, trip }) {
  if (!trip || !trip.addons) {
    return null;
  }

  const translationsAddons = translations.addons || {};
  const deadlineText = translationsAddons.deadline || '';

  return (
    <div className="trip-content app-content">
      
        {trip.addons && trip.addons.map((addonGroup, idx) => { 
          return (

          <div className="trip__addonGroup" key={idx}>
            <div className="trip__addonGroupTitle">
              {addonGroup.title}
            </div>
            <div className={`trip__addons ${(addonGroup.displayStyle || '').toLowerCase() === 'slim' ? 'trip__addons--slim' : ''}`}>
            {addonGroup.addons.map((add) => (
              <div className={`trip__addon ${(addonGroup.displayStyle || '').toLowerCase() === 'slim' ? 'trip__addon--slim' : ''}`} key={add.id}>
                <div className="trip__addon-content">
                  {add.deadline && (
                    <div className="trip__addon-deadline">
                      {deadlineText.replace('{0}', add.deadline)}
                    </div>
                  )}
                  {add.image && (
                    <div
                      className="trip__addon-image"
                      style={{ backgroundImage: `url(${add.image})` }}
                    />
                  )}
                  <div className="trip__addon-body">
                    <div className="trip__addon-title">
                      <div className="trip__addon-name">{add.name}</div>
                      {/*<div className="trip__addon-duration">{add.duration}</div>*/}
                    </div>
                    <ul className="trip__addon-description">
                      {add.description.map((x) => (
                        <li key={x} className="trip__addon-usp">
                          {x}
                        </li>
                      ))}
                    </ul>
                    <div className={`trip__addon-cta ${(addonGroup.displayStyle || '').toLowerCase() === 'slim' ? 'trip__addon-cta--slim' : ''}`}>
                      <a className="trip__addon-link" href={updateUrlParameter(add.url, 'redirectUrl', window.location.href)}>
                        {translationsAddons.order}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>
        )
                      }
        )}
      </div>
  );
}

TripAddons.propTypes = {
  translations: PropTypes.object,
  trip: PropTypes.object,
};

TripAddons.defaultProps = {
  translations: {},
};

export default TripAddons;
