import React from 'react';
import { PropTypes } from 'prop-types';

import './TripAnswers.scss';

function TripAnswers({ settings }) {
  if (!settings) {
    return null;
  }

  return (
    <div className="trip-content app-content trip__faq">
      <a className="trip__faq-link" href={settings.faqLink.url}>
        {settings.faqLink.text}
      </a>
    </div>
  );
}

TripAnswers.propTypes = {
  settings: PropTypes.object,
};

export default TripAnswers;
