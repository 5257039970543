import React from 'react';

import { PropTypes } from 'prop-types';
import './tripHero.scss';

function TripHero({
  title,
  background,
  user,
  location,
  duration,
  greeting,
  startDate,
  daysLeft
}) {
  const builtGreeting = greeting ? greeting.replace('{0}', user) : "";
  const builtTitle = title ? title.replace('{0}', location) : "";

  const currentDate = new Date();
  const startDateDate = new Date(startDate);
  const diffSeconds = startDateDate.getTime() - currentDate.getTime();
  const daysLeftCount = Math.ceil(diffSeconds / (1000 * 3600 * 24));

  return (
    <div
      className="trip__hero"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="app-content trip__hero-content">
        <div className="trip__hero-title">
          <h1>{builtGreeting}</h1>
          <span className="trip__hero-subheading">{builtTitle} {duration}</span>
        </div>
        {daysLeftCount > 0 &&
        <div className="trip__hero-ttl">
          <span className="trip__hero-ttl-number">{daysLeftCount}</span>
          <span className="trip__hero-ttl-text">{daysLeft}</span>
        </div>
        }
      </div>
    </div>
  );
}

TripHero.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  user: PropTypes.string,
  location: PropTypes.string,
  message: PropTypes.string,
  duration: PropTypes.string,
  hideCTAMobile: PropTypes.bool,
  greeting: PropTypes.string
};

export default TripHero;
