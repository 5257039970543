export const se = 
{
  "global": {
    "title": "Alpresor",
    "loginText": "Du måste ange resa först, detta får du enklast genom mina sidor eller boknings bekräftelsen.",
    "navigation": {
      "trip": "Min resa",
      "activity": "Veckoschema",
      "skiing": "Skidåkning",
      "hiking": "Vandring",
      "location": "Orten"
    },
    "login": {
      "form": {
        "bookingRefLabel": "Bokningsnummer",
        "bookingRefPlaceholder": "00000000",
        "emailLabel": "E-mail",
        "emailPlaceholder": "du@exempel.se",
        "submitLabel": "Login",
        "errorMessage": "Något gick fel, se över dina uppgifter och försök igen"
      }
    },
    "external":  "Extern"
  },
  "trip": {
    "hero": {
      "greeting": "Hej {0}",
      "header": "Min resa",
      "message": "Hej {0}, välkommen till din resa",
      "afterTripMessage": "Tack för att du rest med oss {0}. Välkommen åter!",
      "daysLeft": "dagar kvar till avresa",
      "title": "Du reser till {0}"
    },
    "flight": {
      "header": "Reseinformation",
      "travelTime": "Restid",
      "addSki": "Lägg till skidfrakt",
      "luggage": "{0} incheckad väska per person max {1} ingår",
      "skiLuggage": "1 par skidor + stavar, 1 par pjäxor, 1 hjälm - max {0}"
    },
    "summary": {
      "header": "Sammanfattning",
      "header2": "Betalning",
      "isPaid": "Din resa är betald",
      "isCancelled": "Din resa är avbokad",
      "bookingNumber": "Bokningsnummer",
      "bookingDate": "Bokningsdatum",
      "bookingPerson": "Bokad av",
      "bookingPrice": "Totalpris",
      "bookingFee": "Anmälningsavgift",
      "bookingFeeHelp": "Betalas senast {0}",
      "bookingPayment": "Slutbetalning",
      "bookingPaymentHelp": "Betalas senast {0}",
      "hint1": "När resan är fullbetald kan du ladda ner din biljett här",
      "hint2": "I nästa steg väljer du om anmälningsavgiften eller hela resans pris skall betalas",
      "email": "E-post",
      "phoneNumber": "Telefon",
      "paid": "Betalt hittills",
      "leftToPay": "Kvar att betala"
    },
    "tabs": {
      "booking": "Bokning",
      "faq": "FAQ",
      "addons": "Tillval"
    },
    travellers: {
      "header": "Resenärer",
      "name": "Namn",
      "room": "Rum",
      "type": "Typ",
      "typeAdult": "Vuxen",
      "typeChild": "Barn",
      "dateOfBirth": "Född",
      "gender": "Kön",
      "genderMale": "Man",
      "genderFemale": "Kvinna",
      "notice": "Vill du lägga till fler resenärer? Kontakta oss på info@alpresor.se eller <span>0771-20 20 20</span>"
    },
    "destination": {
      "header": "Resmål och hotell",
      "destination": "Resmål",
      "hotel": "Hotell",
      "checkIn": "Check in",
      "checkOut": "Check out",
      "package": "Paket",
      "linkLabel": "Rum och resenärer",
      "linkText": "Se längre ner"
    },
    "includes": {
      "header": "Mina tillval",
      "seeAll": "Se fler tillval",
      "edit": "Ändra",
      "included": "Ingår",
      "skiSchool": "Skidskola",
      "excursion": "Utflykt",
      "skiRental": "Skidhyra",
      "skiPass": "Liftkort",
      "flightMeal": "Flygmat",
      "skiLuggage": "Skidfrakt",
      "transfer": "Transfer",
      "travelInsurance": "Semesterförsäkring",
      "hiking": "Vandring",
      "travelers": "{0} st",
      "pricing":  "á {0}"

    },
    "addons": {
      "deadline": "Boka senast {0}",
      "order": "Lägg till",
      "durationDays": "{0} dagar",
      "durationDay": "{0} dag",
      "addonSkiCategory": "Skidor",
      "addonFlightCategory": "Flyg",
      "addonHikingCategory": "Vandring",
      "addonExcursionCategory": "Andra aktiviteter",
      "addonNoneCategory":  "Övrigt"
    },
    "cta": {
      "depositLeftText": "Säkra din resa - betala {0} kr nu",
      "notPayedText": "Slutbetala din resa",
      "allPayedText": "Din resa är betald",
      "logout":  "Logga ut"
    },
    "activities": {
      "heading": "Veckans aktiviteter",
      "description": "Vi på STS Alpresor arrangerar massvis med utflykter och arrangemang i sällskap av våra guider och andra gäster för er att ta del av under resan."
    },
    "bookingConfirmation": {
        "isPaid": "Ladda ner biljett",
        "notPaid": "Ladda ner bokningsbekräftelse"
    }
  },
  "location": {
    "contact": {
      "title": "Kontakta oss",
      "viewMore": "Visa fler",
      "leader": "Platschef",
      "backLink": "Tillbaka"
    },
    "content": {
      "rootPage": "Orten",
      "openInMaps": "Visa på karta",
      "backLink": "Tillbaka",
      "tripAdvisor": {
        "memberHeader": "Medlemsbetyg",
        "five": "Mycket bra",
        "four": "Bra",
        "three": "Medel",
        "two": "Dåligt",
        "one": "Mycket dåligt",
        "overviewHeader": "Betygöversikt",
        "numRatings": "omdömen från TripAdvisor",
        "subratinglocation": "Läge",
        "subratingsleep": "Sovkvalitet",
        "subratingroom": "Rum",
        "subratingservice": "Service",
        "subratingvalue": "Standard",
        "subratingcleanliness": "Renlighet",
        "subratingfood": "Mat",
        "subratingatmosphere": "Atmosfär"
      }
    }
  },
  "activity": {
    "bookPlace": "Boka plats",
    "bookDeadline": "Boka senast",
    "backToList": "Tillbaka till listan",
    "meta": {
      "date": "Datum",
      "time": "Tid",
      "timeChanged": "(flyttad från {0})",
      "place": "Plats"
    },
    "cta": "Boka",
    "ctaNoAction": "Ej bokningsbar",
    "canceled": "Inställd",
    "disclaimer": "Observera att schemat är preliminärt och kan komma att ändras pga väder och andra omständigheter"
  },
  "excursions": {
    "skiing": {
      "tabs": {
        "lifts": "Liftsystem",
        "weather": "Väder & Snö",
        "webcam": "Webcam"
      }
    },
    "hiking": {
      "tabs": {
        "lifts": "Vandring",
        "weather": "Väder",
        "webcam": "Webcam"
      }
    },
    "weather": {
      "header": "{0} dygnsprognos",
      "weatherTypes": {
        "rain": "Regn",
        "rainshowers": "Regnskurar",
        "snowshowers": "Snöskurar",
        "snow": "Snö",
        "cloudy": "Molnigt",
        "partlycloudy": "Halvklart",
        "sun": "Soligt",
        "sleet": "Snöblandat regn",
        "sleetshowers": "Snöblandade regnskurar",
        "fog": "Dimmigt",
        "fair": "Klart"
      },
      "maxTop": "Min-/max topp",
      "maxValley": "Min-/max dal",
      "minTop": "Min-/max topp",
      "minValley": "Min-/max dal",
      "wind": "Vind",
      "snow": "Snöfall"
    },
    "snow": {
      "header": "Snörapporter",
      "snowTop": "Snödjup topp av pist",
      "snowValley": "Snödjup dal",
      "lastSnow": "Senaste snöfall",
      "snowLastAmount": "Snöfall senaste {0} dygnen",
      "openLifts": "Öppna liftar"
    }
  },
  "DayTypes": {
    "monday": "Måndag",
    "tuesday": "Tisdag",
    "wednesday": "Onsdag",
    "thursday": "Torsdag",
    "friday": "Fredag",
    "saturday": "Lördag",
    "sunday": "Söndag"
  }
}