import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import './activitySchedule.scss';

import ScheduleDay from './scheduleDay';
import { getMarket } from 'helpers/marketHelper';

function ActivitySchedule({
  translations,
  schedule,
  tripId,
  trip,
  locationId,
}) {
  Date.prototype.getWeek = function () {
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  };
  const startDate = new Date('2024-11-28 12:00:00');
  var endDate = new Date();
  endDate.setDate(startDate.getDate() + 7);

  var weeks = [];

  for (let index = 0; index < 18; index++) {
    var s = new Date(startDate);
    s.setDate(startDate.getDate() + index * 7);

    var e = new Date(s);

    e.setDate(s.getDate() + 7);
    weeks.push({
      start: s,
      end: e,
      week: e.getWeek() - 1,
    });
  }

  const tripEndDate =
    trip && trip.endDate
      ? new Date(trip.endDate).toISOString().slice(0, 10)
      : startDate.toISOString().slice(0, 10);
  const userDefaultTripWeek = weeks.find(
    (w) => w.end.toISOString().slice(0, 10) === tripEndDate
  );

  var fullSchedule = weeks.map((w) => {
    const sch = schedule.filter((s) => {
      return s.list.every((l) => l.datetime > w.start && l.datetime < w.end);
    });

    return { ...w, schedule: sch };
  });

  const defWeek = fullSchedule.filter((d) => {
    return d.schedule.length > 0;
  });

  var w = 1;

  if (userDefaultTripWeek) w = userDefaultTripWeek.week;
  else if (defWeek && defWeek.length > 0) w = defWeek[0].week;

  const [week, setWeek] = useState(w);

  if (!translations || !schedule) {
    return null;
  }

  const market = getMarket();
  const weekString =
    market === 'no' ? 'Uke' : market === 'fi' ? 'Viikko' : 'Vecka';

  return (
    <div className="activity__schedule">
      <div
        style={{
          margin: '20px auto',
          border: '1px solid #ebebeb',
          maxWidth: '500px',
        }}
      >
        {
          <select
            onChange={(e) => setWeek(e.target.value)}
            value={week}
            className="login__input"
            style={{
              border: 0,
              padding: '10px',
              fontWeight: 'bold',
              borderRight: '8px solid transparent',
              width: '100%',
            }}
          >
            {fullSchedule !== null &&
              fullSchedule
                .filter((f) => {
                  return f.schedule.length > 0;
                })
                .map((a) => {
                  return (
                    <option value={a.week} key={a.week}>
                      {weekString} {a.week}
                    </option>
                  );
                })}
          </select>
        }
      </div>

      {fullSchedule !== null &&
        fullSchedule
          .filter((w) => w.week == week && w.schedule.length > 0)
          .map((d) => {
            var days = {};

            for (let i = 0; i < d.schedule.length; i++) {
              const e = d.schedule[i];

              for (let j = 0; j < e.list.length; j++) {
                const element = e.list[j];

                const date = element.datetime.toLocaleDateString('sv-se');
                if (days[date]) {
                  days[date].list.push(element);
                } else {
                  days[date] = {
                    list: [element],
                    title: element.date,
                  };
                }
              }
            }

            return Object.keys(days).map((key) => {
              const s = days[key];

              return (
                <ScheduleDay
                  key={s.title}
                  day={s}
                  tripId={tripId}
                  translations={translations}
                  trip={trip}
                  locationId={locationId}
                />
              );
            });
          })}
    </div>
  );
}

ActivitySchedule.propTypes = {
  translations: PropTypes.object,
  schedule: PropTypes.array,
  tripId: PropTypes.string,
};

export default ActivitySchedule;
