import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.scss';

import Home from 'containers/home/Home';
import Login from 'containers/login/Login';
import Framework from 'containers/framework/Framework';
import Trip from 'containers/trip/Trip';
import Skiing from 'containers/skiing/Skiing';
import Hiking from 'containers/hiking/Hiking';
import Location from 'containers/location/Location';
import Activity from 'containers/activity/Activity';
import ErrorComponent from 'components/error/Error';

export default function App() {
  const [tripId, setTripId] = useState(false);
  const [locationId, setLocationId] = useState(false);
  const [locationName, setLocationName] = useState(false);

  const frameworkProperties = {
    tripId,
    setTripId,
    locationId,
    setLocationId,
    locationName,
    setLocationName
  };

  return (
    <Router>
      <Route
        key="always"
        path="*"
        render={(props) => {
          // Takes the user to the top of EVERY page change
          window.scrollTo(0, 0);

          return null;
        }}
      />
      <Switch>
        <Route exact path="/">
          <Home {...frameworkProperties} />
        </Route>
        <Route exact path="/login">
          <Framework basePath="login" hideNavigation={true}>
            <Login />
          </Framework>
        </Route>
        <Route path="/:path">
          <Switch>
            <Route path="/trip/:id">
              <Framework basePath="trip" {...frameworkProperties}>
                <Trip />
              </Framework>
            </Route>
            <Route path="/activity/:id">
              <Framework basePath="activity" {...frameworkProperties}>
                <Activity />
              </Framework>
            </Route>
            <Route path="/hiking/:id">
              <Framework basePath="hiking" {...frameworkProperties}>
                <Hiking />
              </Framework>
            </Route>
            <Route path="/skiing/:id">
              <Framework basePath="skiing" {...frameworkProperties}>
                <Skiing />
              </Framework>
            </Route>
            <Route path="/location/:id">
              <Framework basePath="location" {...frameworkProperties}>
                <Location />
              </Framework>
            </Route>
            <Route path="/">
              <Framework basePath="">
                <ErrorComponent source="App" />
              </Framework>
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Router>
  );
}
