import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './locationLeaders.scss';

function LocationLeaders({ location, translations, locationId, locationName }) {
  if (!location) {
    return null;
  }

  const backLinkText = translations.backLink
    ? translations.backLink.replace('{0}', locationName)
    : locationName;

  return (
    <div>
      <div
        className="location-l__hero"
        style={{ backgroundImage: `url(${location.image})` }}
      >
        <div className="app-content">
          <h1>{location.title}</h1>
        </div>
      </div>
      <div className="location-l__contact">
        <div className="location-l__contact-title">
          {location.contact.title}
        </div>
        {location.contact.contactDescription && (
          <span
            className="location-l__contact-email"
          >
            {location.contact.contactDescription}
          </span>
        )}
        {location.contact.phone && (
          <a
            href={`tel:${location.contact.phone}`}
            className="location-l__contact-phone"
          >
            {location.contact.phone}
          </a>
        )}
      </div>
      <div className="app-content">
        <Link to={`/location/${locationId}`} className="location-l__back">
          {backLinkText}
        </Link>
      </div>
      <div className="location-l__leaders app-content">
        {location.leaders &&
          location.leaders.map((l) => (
            <div className="location-l__leader" key={l.name}>
              <div className="location-l__leader-leader">
                {l.isLeader ? translations.leader : <>&nbsp;</>}
              </div>
              <div className="location-l__leader-image">
                <div
                  className="location-l__leader-file"
                  style={{ backgroundImage: `url(${l.image})` }}
                ></div>
              </div>
              <div className="location-l__leader-name">{l.name}</div>
              {l.knownLanguages && !!l.knownLanguages.length && (
                <div className="location-l__leader-language-wrapper">
                  {l.knownLanguages.map(lang => (
                    <span className={`location-l__leader-language location-l__leader-language__${lang.toLowerCase()}`} />
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

LocationLeaders.propTypes = {
  translations: PropTypes.object,
  location: PropTypes.object,
  locationId: PropTypes.string,
  locationName: PropTypes.string,
};

export default LocationLeaders;
