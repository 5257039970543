import React from 'react';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './Skiing.scss';
import SkiingFramework from './shared/skiingFramework';
import SkiingLifts from './shared/skiingLifts';
/*import SkiingWeather from './shared/skiingWeather';*/
import SkiingWebcams from './shared/skiingWebcams';
import SingleWebcam from './shared/skiingWebcam';
import ErrorComponent from 'components/error/Error';
import LoaderComponent from 'components/loader/Loader';
import locations from 'components/locationSelector/locations'

function Skiing({
  setLocationId,
  translations,
  weather,
  location,
  setTripId,
  trip,
  tripId,
}) {
  let { id } = useParams();
  const routerLocation = useLocation();
  const routerList = routerLocation.pathname.split('/');
  const lastLevelPath = routerList[routerList.length - 1];
  const secondLastLevelPath = routerList[routerList.length - 2];
  const locationId = id;
  const locationSkiing = (location && location.skiing) || {};
  const excursionsTranslations = translations && translations.excursions;


  if (!location) {
    setLocationId && setLocationId(String(locationId));
    
    if (!tripId) {
      trip && setTripId && setTripId(String(trip.id));
    }

    return <LoaderComponent />;
  }

  if (!excursionsTranslations || !location || !Object.keys(location).length) {
    return <ErrorComponent useLogo={false} source="Skiing" />;
  }

  const locationName = locations[locationId]

  return (
    <div className="skiing-content">
      <Switch>
        <Route exact path="/skiing/:id">
          <SkiingFramework
            translations={excursionsTranslations.skiing}
            lastLevelPath={lastLevelPath}
            locationId={locationId}
            location={locationSkiing}
          >
            <SkiingLifts locationLifts={location.lifts} globalTranslations={translations.global} locationName={locationName} />
          </SkiingFramework>
        </Route>
        { /*
        <Route exact path="/skiing/:id/weather">
          <SkiingFramework
            translations={excursionsTranslations.skiing}
            lastLevelPath={lastLevelPath}
            locationId={locationId}
            location={locationSkiing}
          >
            <SkiingWeather
              translations={excursionsTranslations}
              locationWeather={weather}
              isSummer={trip && !!trip.isSummer}
            />
          </SkiingFramework>
        </Route>
        */}
        <Route exact path="/skiing/:id/webcam">
          <SkiingFramework
            translations={excursionsTranslations.skiing}
            lastLevelPath={lastLevelPath}
            locationId={locationId}
            location={locationSkiing}
          >
            <SkiingWebcams
              translations={excursionsTranslations.skiing}
              locationWebcams={location.webcams}
              htmlWebcams={location.htmlWebcams}
            />
          </SkiingFramework>
        </Route>
        <Route exact path="/skiing/:id/webcam/:camId">
          <SkiingFramework
            translations={excursionsTranslations.skiing}
            lastLevelPath={lastLevelPath}
            secondLastLevelPath={secondLastLevelPath}
            locationId={locationId}
            location={locationSkiing}
          >
            <SingleWebcam
              translations={translations}
              locationWebcams={location.webcams}
              htmlWebcams={location.htmlWebcams}
            />
          </SkiingFramework>
        </Route>
      </Switch>
    </div>
  );
}

Skiing.propTypes = {
  setLocationId: PropTypes.func,
  setTripId: PropTypes.func,
  trip: PropTypes.object,
  translations: PropTypes.object,
  location: PropTypes.object,
  fetchedLocation: PropTypes.bool,
};

export default Skiing;
