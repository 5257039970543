import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './SecondaryNavigation.scss';

function SecondaryNavigation({ options }) {
  if (!options) {
    return null;
  }

  return (
    <div className="secnav-container">
      <nav className="secnav-content">
        {options.map((opt) => {
          return (
            <Link
              key={opt.url}
              className={`secnav-link ${opt.isActive ? 'is-active' : ''}`}
              to={opt.url}
            >
              {opt.name}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}

SecondaryNavigation.propTypes = {
  options: PropTypes.array,
};

export default SecondaryNavigation;
