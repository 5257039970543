export const fi = 
{
  "global": {
    "title": "Alppimatkat",
    "loginText": "Sinun on lisättävä matka ensin, tämän löydät Minun Matkani -osiosta kotisivuiltamme tai varausvahvistuksestasi.",
    "navigation": {
      "trip": "Matkani",
      "activity": "Viikko-ohjelma",
      "skiing": "Laskettelu",
      "hiking": "Vaellus",
      "location": "Kohde"
    },
    "login": {
      "form": {
        "bookingRefLabel": "Varausnumero",
        "bookingRefPlaceholder": "00000000",
        "emailLabel": "E-mail",
        "emailPlaceholder": "sinä@esimerkki.fi",
        "submitLabel": "Login",
        "errorMessage": "Jokin meni väärin, tarkistathan tietosi ja kokeile uudestaan"
      }
    }
  },
  "trip": {
    "hero": {
      "greeting": "Hei {0}",
      "header": "Matkani",
      "message": "Hei {0}, tervetuloa tarkastelemaan matkaasi",
      "afterTripMessage": "Kiitos {0}, että matkustit kanssamme. Tervetuloa uudestaan!",
      "daysLeft": "lähtöön aikaa",
      "title": "Matkustat kohteeseen {0}"
    },
    "flight": {
      "header": "Matkan tiedot",
      "travelTime": "Matka-aika",
      "addSki": "Lisää suksirahti",
      "luggage": "Hintaan sisältyy {0} ruumamatkatavara henkilöä kohden {1}",
      "skiLuggage": "1 pari suksia + sauvoja, 1 pari monoja, 1 kypärä - max {0}"
    },
    "summary": {
      "header": "Yhteenveto",
      "header2": "Maksuja",
      "isPaid": "Matkasi on maksettu",
      "isCancelled": "Matkasi on peruutettu",
      "bookingNumber": "Varausnumero",
      "bookingDate": "Varauspäivämäärä",
      "bookingPerson": "Varaaja",
      "bookingPrice": "Kokonaishinta",
      "bookingFee": "Varausmaksu",
      "bookingFeeHelp": "Viimeksi maksettu {0}",
      "bookingPayment": "Loppumaksu",
      "bookingPaymentHelp": "Viimeksi maksettu {0}",
      "hint1": "Kun matka on kokonaan maksettu voit ladata matkalippusi tästä",
      "hint2": "Seuraavassa vaiheessa valitset mikäli maksat varausmaksun tai koko matkat hinnan",
      "email": "Sähköposti",
      "phoneNumber": "Puhelin",
      "paid": "Maksettu",
      "leftToPay": "Maksettavaa jäljellä"
    },
    "tabs": {
      "booking": "Varaus",
      "faq": "FAQ",
      "addons": "Lisäpalvelut"
    },
    "travellers": {
      "header": "Matkustajat",
      "name": "Nimi",
      "room": "Huone",
      "type": "Tyyppi",
      "typeAdult": "Aikuinen",
      "typeChild": "Lapsi",
      "dateOfBirth": "Syntymäpäivä",
      "gender": "Sukupuoli",
      "genderMale": "Mies",
      "genderFemale": "Nainen",
      "notice": "Haluatko lisätä matkustajia? Ota yhteyttä info@alppimatkat.fi tai soita <span>09-67 13 14</span>"
    },
    "destination": {
      "header": "Kohde ja hotelli",
      "destination": "Matkakohde",
      "hotel": "Hotelli",
      "checkIn": "Sisäänkirjautuminen",
      "checkOut": "Uloskirjautuminen",
      "package": "Paketti",
      "linkLabel": "Huone ja matkustaja",
      "linkText": "Katso alempana"
    },
    "includes": {
      "header": "Minun valintani",
      "seeAll": "Katso muut lisävalinnat",
      "edit": "Muokkaa",
      "included": "Sisältää",
      "skiSchool": "Hiihtokoulu",
      "excursion": "Retket",
      "skiRental": "Suksivuokra",
      "skiPass": "Hissilippu",
      "flightMeal": "Lentokoneruokailu",
      "skiLuggage": "Suksikuljetus",
      "transfer": "Lentokenttäkuljetus",
      "travelInsurance": "Matkavakuutus",
      "hiking": "Vaellus",
      "travelers": "{0} kpl",
      "pricing": "{0}/kpl"
    },
    "addons": {
      "deadline": "Varaa viimeistään {0}",
      "order": "Lisää",
      "durationDays": "{0} päivää",
      "durationDay": "{0} päivä",
      "addonSkiCategory": "Sukset",
      "addonFlightCategory": "Lento & matka",
      "addonHikingCategory": "Vaellus",
      "addonExcursionCategory": "Muut aktiviteetit",
      "addonNoneCategory": "Muut"
    },
    "cta": {
      "depositLeftText": "Varmista matkasi - Maksa {0} nyt",
      "notPayedText": "Maksa matkasi loppumaksu",
      "allPayedText": "Matkasi on maksettu",
      "logout": "Kirjaudu ulos"
    },
    "activities": {
      "heading": "Viikon aktiviteetit",
      "description": "Me STS Alppimatkoilla järjestämme paljon retkiä ja aktiviteettejä oppaidemme ja asiakkaidemme kanssa, jonne voit osallistua matkan aikana."
    },
    "bookingConfirmation": {
      "isPaid": "Lataa matkalippu",
      "notPaid": "Lataa varausvahvistus"
    }
  },
  "location": {
    "contact": {
      "title": "Ota yhteyttä",
      "viewMore": "Näytä lisää",
      "leader": "Kohdepäällikkö",
      "backLink": "Takaisin kohde"
    },
    "content": {
      "rootPage": "Kohde",
      "openInMaps": "Näytä kartalla",
      "backLink": "Takaisin",
      "tripAdvisor": {
        "memberHeader": "Asiakasarvosana",
        "five": "Erinomainen",
        "four": "Erittäin hyvä",
        "three": "Keskitaso",
        "two": "Huono",
        "one": "Todella huono",
        "overviewHeader": "Totalvurdering",
        "numRatings": "Tripadvisorin arvostelut",
        "subratinglocation": "Sijainti",
        "subratingsleep": "Vuoteen mukavuus",
        "subratingroom": "Huone",
        "subratingservice": "Palvelu",
        "subratingvalue": "Taso",
        "subratingcleanliness": "Siisteys",
        "subratingfood": "Ruoka ",
        "subratingatmosphere": "Ilmapiiri"
      }
    }
  },
  "activity": {
    "bookPlace": "Varaa paikka",
    "backToList": "Takaisin listaukseen",
    "bookDeadline": "Varaa viimeistään",
    "meta": {
      "date": "Päivämäärä",
      "time": "Aika",
      "timeChanged": "(Muutettu ajasta {0})",
      "place": "Paikka"
    },
    "cta": "Varaa",
    "ctaNoAction": "Ei varattavissa",
    "canceled": "Peruutettu",
    "disclaimer": "Huomaa, että aikataulu on alustava ja se voi muuttua sään tai muiden olosuhteiden takia."
  },
  "excursions": {
    "skiing": {
      "tabs": {
        "lifts": "Hissijärjestelmä",
        "weather": "Sää ja lumi",
        "webcam": "Webcam"
      }
    },
    "hiking": {
      "tabs": {
        "lifts": "Vaellus",
        "weather": "Sää",
        "webcam": "Webcam"
      }
    },
    "weather": {
      "header": "{0} päivän ennuste",
      "weatherTypes": {
        "rain": "Sadetta",
        "rainshowers": "Sadetta",
        "snowshowers": "Lumisadetta",
        "snow": "Lumi",
        "cloudy": "Pilvistä",
        "partlycloudy": "Puolipilvistä",
        "sun": "Aurinkosta",
        "sleet": "Räntäsadetta",
        "sleetshowers": "Räntäsadetta",
        "fog": "Sumuista",
        "fair": "Selkeää"
      },
      "maxTop": "Min-/max ylhäällä",
      "maxValley": "Min-/max laaksossa",
      "minTop": "Min-/max ylhäällä",
      "minValley": "Min-/max laaksossa",
      "wind": "Tuuli",
      "snow": "Lumisade"
    },
    "snow": {
      "header": "Lumiraportti",
      "snowTop": "Lumen syvyys vuorilla",
      "snowValley": "Lumen syvyys laaksossa",
      "lastSnow": "Viimeisin lumisade",
      "snowLastAmount": "Lumisade viimeisen {0} päivän aikana",
      "openLifts": "Avoinna olevat hissit"
    }
  },
  "DayTypes": {
    "monday": "Maanantai",
    "tuesday": "Tiistai",
    "wednesday": "Keskiviikko",
    "thursday": "Torstai",
    "friday": "Perjantai",
    "saturday": "Lauantai",
    "sunday": "Sunnuntai"
  }
}