export const TRIP_STATUS = {
  before: 1,
  during: 2,
  after: 3,
};

export const MARKET_COOKIE = 'market';
export const NOTIFICATION_ACCEPTED = 'notificationsAccepted';

export const MARKET_CODE = {
  no: 'no',
  se: 'se',
  fi: 'fi',
};

export const PAGE_TYPES = {
  listPage: 'listPage',
  contentPage: 'contentPage',
};
