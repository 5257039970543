import React from 'react';
import { PropTypes } from 'prop-types';

import './skiingLifts.scss';
import LinkList from 'components/linkList/LinkList';
import MapImage from 'components/mapImage/MapImage';

function SkiingLifts({ locationLifts, globalTranslations, locationName}) {
  if (!locationLifts) {
    return null;
  }

  return (
    <div>
      <MapImage image={locationLifts.image} />
      <div className="location-s__contact app-content ">
        <h1 className="location-s__contact-content">{ locationName }</h1>
      </div>
      <div className="app-content no-padding">
        <LinkList links={locationLifts.links} externalTranslation={globalTranslations.external}/>
      </div>
    </div>
  );
}

SkiingLifts.propTypes = {
  globalTranslations: PropTypes.object,
  locationLifts: PropTypes.object,
};

export default SkiingLifts;
