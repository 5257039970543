import React from 'react';
import { PropTypes } from 'prop-types';

import './scheduleDay.scss';

import ScheduleActivity from './scheduleActivity';

function ScheduleDay({ translations, day, tripId, trip, locationId }) {
  if (!translations || !day) {
    return null;
  }

  const activityList = day.list || [];

  return (
    <div>
      <div className="activity__schedule-day">
        { <div className="app-content">{day.title}</div>}
      </div>
      <div className="activity__schedule-list">
        {activityList.map((i) => (
          <ScheduleActivity
            key={i.title}
            activity={i}
            tripId={tripId}
            translations={translations}
            trip={trip}
            locationId={locationId}
          />
        ))}
      </div>
    </div>
  );
}

ScheduleDay.propTypes = {
  translations: PropTypes.object,
  day: PropTypes.object,
  tripId: PropTypes.string,
};

export default ScheduleDay;
