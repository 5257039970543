import React from 'react';
import { updateUrlParameter } from 'helpers/urlHelper';
import { getMarket, getLogo } from 'helpers/marketHelper';
import './Error.scss';

function ErrorComponent({ useLogo, source, translations }) {
  const marketId = getMarket();
  const logo = getLogo(marketId);
  const urlParts = window.location.hostname.split(".")
  const language = urlParts[urlParts.length - 1]

  return (
    <div className="error-content" id="error-view">
      {useLogo && <img src={logo} className="error-logo" alt="logo" />}
      {language === "se" 
        ? (<h1> Hoppsan, något gick fel </h1>)
        : language === "no" 
        ? (<h1> Oops! Noe gikk galt </h1>)
        : language === "fi" 
        ? (<h1> Hups, jotain meni pieleen </h1>)
        : (<h1> Oops, something went wrong </h1>) 
      }
      <a className="activity-i__cta" href={updateUrlParameter('/', 'redirectUrl', window.location.href)}>
          {language === "se" ? "Försök igen" :
            language === "no" ? "Prøv igjen" :
            language === "fi" ? "Kokeile uudestaan" :
          "Reload" }
      </a>
    </div>
  );
}

ErrorComponent.propTypes = {};

export default ErrorComponent;
