import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import LocationSelector from 'components/locationSelector/LocationSelector';

import './locationStart.scss';
import LinkList from 'components/linkList/LinkList';
import MapImageWithMapLink from 'components/mapImage/MapImageWithMapLink';

function LocationStart({ locationId, location, translations, locationName }) {
  if (!location) {
    return null;
  }

  const realParentPath = `/location/${locationId}/information`;
  const locationLinks = location.links;
  const firstLevelLinks = locationLinks.map((l) => {
    return {
      text: l.name,
      url: `${realParentPath}/${l.url}`,
    };
  });

  return (
    <div>
      <div className="location-m ">
        <MapImageWithMapLink image={location.mapImage} isSmall={true} location={location} />
      </div>
      <div className="location-s__contact app-content ">
        <h1 className="location-s__contact-content">{ locationName }</h1>
      </div>
      <div className="location-s__contact app-content no-padding">
        <div className="location-s__contact-image">
          <div
            className="location-s__contact-file"
            style={{ backgroundImage: `url(${location.contact.image})` }}
          ></div>
        </div>
        <div className="location-s__contact-content">
          <div className="location-s__contact-title">
            {location.contact.title}
          </div>
          <Link
            to={`/location/${locationId}/leaders`}
            className="location-s__contact-link"
          >
            {translations.contact.title}
          </Link>
        </div>
      </div>
      <div className="app-content no-padding">
        <LinkList links={firstLevelLinks} internalLinks={true} />

        <div className=''>
            <LocationSelector />
        </div>

      </div>

    </div>
  );
}

LocationStart.propTypes = {
  translations: PropTypes.object,
  locationId: PropTypes.string,
  location: PropTypes.object,
};

export default LocationStart;
