import React, { useState, useEffect } from 'react';
import NotificationMessage from 'components/notification/NotificationMessage'
import {
  fetchNotifications,
} from 'helpers/fetchHelper';
import { setLocalStorage } from 'helpers/uiHelper';
import { NOTIFICATION_ACCEPTED } from 'shared/constants';
import './tripNotifications.scss';

const TripNotifications = ({tripId}) => {
  const className = "trip__notifications";

  const [notifications, setNotifications] = useState();

  useEffect(() => {
    if (!notifications && tripId) {
      fetchNotifications(tripId, setNotifications);
    } else {
      setLocalStorage(NOTIFICATION_ACCEPTED, notifications.data.notifications.map(n => n.id).join(';'));
    }
  }, [notifications, tripId]);

  const noticeDate = (datetime) => (
    new Date(datetime).toLocaleString('sv-SE') 
  );

  return (
    <div className={className}>
      {notifications && notifications.fetched && notifications.data.notifications.map((notice, idx) => (
        <div className={`${className}-row`} key={idx}>
          <NotificationMessage notification={notice} tripId={tripId} />
          <div className={`${className}-row-date`}>{noticeDate(notice.occured)}</div>
        </div>
      ))}
    </div>
  );
};

export default TripNotifications;