import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './locationListHero.scss';

function LocationListHero({ parentPage, backLinkLabel, content }) {
  if (!content) {
    return null;
  }

  if (content.image) {
    return (
      <div
        className="location-ll__hero"
        style={{ backgroundImage: `url(${content.image})` }}
      >
        <div className="app-content no-padding">
          <h1>{content.title}</h1>
          <div>
            <Link to={parentPage} className="location-ll__back">
              {backLinkLabel}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="location-ll__title">
      <div className="app-content">
        <h1>{content.title}</h1>
        <div>
          <Link to={parentPage} className="location-ll__back">
            {backLinkLabel}
          </Link>
        </div>
      </div>
    </div>
  );
}

LocationListHero.propTypes = {
  content: PropTypes.object,
  parentPath: PropTypes.string,
  backLinkLabel: PropTypes.string,
  locationId: PropTypes.string,
};

export default LocationListHero;
