import React from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './Location.scss';

import LocationStart from './shared/locationStart';
import LocationLeaders from './shared/locationLeaders';
import LocationRoutes from './shared/locationRoutes';
import ErrorComponent from 'components/error/Error';
import LoaderComponent from 'components/loader/Loader';
import locations from 'components/locationSelector/locations';

function Location({
  translations,
  fetchedLocation,
  location,
  trip,
  setLocationId,
  setTripId,
  tripId
}) {
  let { id } = useParams();
  const locationId = id;
  const locationTranslations = translations && translations.location;



  if (!location) {
    setLocationId && setLocationId(String(locationId));

    if (!tripId) {
      trip && setTripId && setTripId(String(trip.id));
    }

    return <LoaderComponent />;
  }

  if (!locationTranslations || !location || !Object.keys(location).length) {
    return <ErrorComponent useLogo={false} source="Location" />;
  }

  
  const locationName = locations[locationId];

  return (
    <div className="location-content">
      <Switch>
        <Route exact path="/location/:id">
          <LocationStart
            location={location.content}
            translations={locationTranslations}
            locationId={locationId}
            locationName={locationName}
          />
        </Route>
        <Route exact path="/location/:id/leaders">
          <LocationLeaders
            location={location.content.contact}
            translations={locationTranslations.contact}
            locationId={locationId}
            locationName={location.name}
          />
        </Route>

        <Route
          exact
          path="/location/:id/information"
          render={(props) => (
            <Redirect to={`/location/${props.match.params.id}`} />
          )}
        />

        <Route path="/location/:id/information">
          <LocationRoutes
            parentPath="/location/:id/information"
            locationLinks={location.content.links}
            locationId={locationId}
            translations={locationTranslations.content}

          />
        </Route>
      </Switch>
    </div>
  );
}

Location.propTypes = {
  setLocationId: PropTypes.func,
  setTripId: PropTypes.func,
  trip: PropTypes.object,
  translations: PropTypes.object,
  location: PropTypes.object,
  fetchedLocation: PropTypes.bool,
};

export default Location;
