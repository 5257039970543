import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './NotificationMessage.scss';

function NotificationMessage({ notification, tripId }) {
  if (!notification) {
    return null;
  }

  const getWrapper = (n, content) => {
    if (n.activityId) {
      return (
        <Link
          to={`/activity/${tripId}/${n.activityId}`}
          className="note-message__link"
        >
          {content}
        </Link>
      );
    } else if (n.url) {
      return (
        <a href={n.url} className="note-message__link">
          {content}
        </a>
      );
    }

    return <span>{content}</span>;
  };

  let content = null;

  if (notification.title) {
    const message = notification.message ? `: ${notification.message}` : null;
    content = (
      <div>
        {getWrapper(notification, notification.title)}
        {message}
      </div>
    );
  } else {
    const message = notification.message ? notification.message : null;
    content = <div>{getWrapper(notification, message)}</div>;
  }

  return <div className="note-message">{content}</div>;
}

NotificationMessage.propTypes = {
  notification: PropTypes.object,
  tripId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default NotificationMessage;
