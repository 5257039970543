import React from 'react';

import { PropTypes } from 'prop-types';
import './tripTravellers.scss';

function TripTravellers({ tripTravellers, translationsTravellers }) {
  const data = tripTravellers.map((trav) => {
    const rows = Object.keys(trav).map((key) => {
      return (
        <div className="trip__travellers-row" key={key}>
          <div className="trip__travellers-label">
            <div>{translationsTravellers[key]}:</div>
          </div>
          <div className="trip__travellers-value">{trav[key]}</div>
        </div>
      );
    });

    return (
      <div className="trip__travellers-person" key={trav.name}>
        {rows}
      </div>
    );
  });

  const dataDesktop = (
    <table className="trip__travellers-table">
      <tbody>
      {tripTravellers.map((trav, idx) => (
        <tr className="trip__travellers-row" key={idx}>
          <td>{trav.name}</td>
          <td>{trav.room}</td>
          <td>{trav.type}</td>
          <td>{trav.dateOfBirth}</td>
          <td>{trav.gender}</td>
        </tr>
      ))}
      </tbody>
    </table>
  );

  return (
    <div className="trip__travellers">
      {data}
      {dataDesktop}
    </div>
  );
}

TripTravellers.propTypes = {
  tripTravellers: PropTypes.array,
  translationsTravellers: PropTypes.object,
};

export default TripTravellers;
