import React from 'react';
import { PropTypes } from 'prop-types';

import './locationListPage.scss';

import LocationListHero from './locationListHero';
import LinkList from 'components/linkList/LinkList';

function LocationListPage({
  currentPath,
  parentPath,
  parentName,
  page,
  locationId,
  translations,
}) {
  if (!page) {
    return null;
  }
  const currentPage = currentPath.replace(':id', locationId);
  const parentPage = parentPath.replace(':id', locationId);

  const pageLinks = page.links;
  const childPages = pageLinks.filter((l) => !l.name.toLowerCase().startsWith('x ')).map((l) => {
    return {
      text: l.name,
      url: `${currentPage}/${l.url}`,
    };
  });

  return (
    <div>
      <LocationListHero
        content={page.content}
        parentPage={parentPage}
        backLinkLabel={translations.backLink}
      />
      <div className="app-content no-padding">
        <LinkList links={childPages} internalLinks={true} />
      </div>
    </div>
  );
}

LocationListPage.propTypes = {
  page: PropTypes.object,
  currentPath: PropTypes.string,
  parentPath: PropTypes.string,
  parentName: PropTypes.string,
  locationId: PropTypes.string,
  translations: PropTypes.object,
};

export default LocationListPage;
