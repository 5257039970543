import React from 'react';
import { PropTypes } from 'prop-types';

import './tripFlight.scss';

function TripFlight({ flight, translations }) {
  if (!flight || !translations) {
    return <div className="trip__flight"></div>;
  }

  const luggageText = flight.luggage && translations.luggage
    .replace('{0}', flight.luggage.bags)
    .replace('{1}', flight.luggage.weight);
  const skiText = flight.luggage && translations.skiLuggage.replace(
    '{0}',
    flight.luggage.skiWeight
  );

  const getSkiLink = flight.luggage && (flight.luggage.skiIncluded ? (
    <div className="trip__flight-summary">1 x {flight.luggage.skiWeight}</div>
  ) : (
    <a href={flight.luggage.skiLink} className="trip__flight-add">
      {translations.addSki}
    </a>
  ));

  return (
    <div className="trip__flight">
      <div className="trip__flight-row">
        <div className="trip__flight-information">
          <div className="trip__flight-header is-departure">
            <div className="trip__flight-date">{flight.fromHome.date}</div>
            <div className="trip__flight-flight">{flight.fromHome.flight}</div>
          </div>
          <div className="trip__flight-timeline">
            <div className="trip__flight-departure">
              <div className="trip__flight-airport is-departure">
                {flight.fromHome.departure.time},{' '}
                {flight.fromHome.departure.airport}
              </div>
              <div className="trip__flight-duration">
                {translations.travelTime}: {flight.fromHome.duration}
              </div>
            </div>
            <div className="trip__flight-arrival">
              <div className="trip__flight-airport is-arrival">
                {flight.fromHome.arrival.time},{' '}
                {flight.fromHome.arrival.airport}
              </div>
            </div>
          </div>
        </div>
        <div className="trip__flight-information">
          <div className="trip__flight-header is-arrival">
            <div className="trip__flight-date">{flight.toHome.date}</div>
            <div className="trip__flight-flight">{flight.toHome.flight}</div>
          </div>
          <div className="trip__flight-timeline">
            <div className="trip__flight-departure">
              <div className="trip__flight-airport is-departure">
                {flight.toHome.departure.time},{' '}
                {flight.toHome.departure.airport}
              </div>
              <div className="trip__flight-duration">
                {translations.travelTime}: {flight.toHome.duration}
              </div>
            </div>
            <div className="trip__flight-arrival">
              <div className="trip__flight-airport is-arrival">
                {flight.toHome.arrival.time}, {flight.toHome.arrival.airport}
              </div>
            </div>
          </div>
        </div>
      </div>

      {flight.luggage && <div className="trip__flight-luggage">
        <div className="trip__flight-bag">
          <div className="trip__flight-summary">
            {flight.luggage.bags} x {flight.luggage.weight}
          </div>
          <div className="trip__flight-luggage-help">{luggageText}</div>
        </div>
        <div className="trip__flight-ski">
          <div>{getSkiLink}</div>
          <div className="trip__flight-luggage-help">{skiText}</div>
        </div>
      </div>}
    </div>
  );
}

TripFlight.propTypes = {
  flight: PropTypes.object,
  translations: PropTypes.object,
};

export default TripFlight;
