import React from 'react';
import { PropTypes } from 'prop-types';

import './TripBooking.scss';

import TripSummary from '../shared/tripSummary';
import TripFlight from '../shared/tripFlight';
import TripTravellers from '../shared/tripTravellers';
import TripDestination from '../shared/tripDestination';
import TripIncludes from '../shared/tripIncludes';
import TripAfterInfo from '../shared/tripAfterInfo';
import { Link } from 'react-router-dom';
import { clearOfflineData } from '../../../helpers/offlineHelper';

function TripBooking({ translations, trip }) {
  if (!translations) {
    return null;
  }

  return (
    <div className="trip-content">
      {trip && trip.afterTripArea && (
        <div className="app-content trip__row">
          <TripAfterInfo {...trip.afterTripArea} />
        </div>
      )}
      <div className="app-content trip__row align-top">
        <TripSummary
          header={translations.summary.header}
          header2={translations.summary.header2}
          tripSummary={trip.summary}
          translationSummary={translations.summary}
          isPaid={trip.isPaid}
          isCancelled={trip.isCancelled}
          bookingConfirmationLink={trip.bookingConfirmation}
          cta={trip.cta}
          tripContact={trip.contact}
          hint1={translations.summary.hint1}
          hint2={translations.summary.hint2}
        />
      </div>
      <div className="app-content trip__header">{translations.flight.header}</div>
      <div className="app-content trip__row">
        <TripFlight
          flight={trip.flight}
          translations={translations.flight}
        />
        <TripDestination
          tripDestination={trip.destination}
          translationsDestination={translations.destination}
        />
      </div>
      <div className="app-content trip__header">
        {translations.includes.header}
        <Link to={`/trip/${trip.id}/addons`} className="trip__includes-addons">
          {`${translations.includes.seeAll} +`}
        </Link>
        </div>
      <div className="app-content trip__row">
        <TripIncludes
          includes={trip.includes}
          translations={translations.includes}
          tripId={trip.id}
        />
      </div>
      <div className="app-content trip__header">{translations.travellers.header}</div>
      <div className="app-content trip__row">
        <TripTravellers
          header={translations.travellers.header}
          tripTravellers={trip.travellers}
          translationsTravellers={translations.travellers}
        />
      </div>
      <div className="app-content trip__row">
        <div className="trip__bottomNotice" dangerouslySetInnerHTML={{__html: translations.travellers.notice }} />
      </div>
      <div className="app-content trip__row">
        <Link
          onClick={() => { clearOfflineData(); }}
          to="/login"
          className="btn btn--transparent"
        >
          {translations.cta.logout}
        </Link>
        <br />
      </div>
    </div>
  );
}

TripBooking.propTypes = {
  translations: PropTypes.object,
  trip: PropTypes.object,
};

export default TripBooking;
