import React from 'react';
import { PropTypes } from 'prop-types';

import './activityList.scss';

import ActivitySchedule from './activitySchedule';
import ErrorComponent from 'components/error/Error';

function ActivityList({ activities, translations, tripId, trip, location, locationId }) {

  const activitiesHero = (activities && activities.hero) || {};

  if (!activities) {
    return <ErrorComponent useLogo={false} source="ActivityList" />;
  }

  return (
    <div className="activities-content">
      <div
        className="activities__hero"
        style={{ backgroundImage: `url(${activitiesHero.image})` }}
      >
        <div className="activities__hero-content">
          <div className="activities__hero-title">{activitiesHero.heading}</div>
          <div className="activities__hero-description">
            {activitiesHero.description}
          </div>
        </div>
      </div>
      {activities.promotion && (
        <div className="activities__promotion">{activities.promotion}</div>
      )}
      <ActivitySchedule
        tripId={tripId}
        translations={translations}
        schedule={activities.schedule}
        trip={trip}
        locationId={locationId}
      />
      {translations.disclaimer && (
        <div className="activities__disclaimer">
          <em>{translations.disclaimer}</em>
        </div>) }
    </div>
  );
}

ActivityList.propTypes = {
  translations: PropTypes.object,
  activities: PropTypes.object,
  tripId: PropTypes.string,
};

export default ActivityList;
