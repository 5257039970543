import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import './skiingWebcam.scss';

const SkiingWebcam = ({ locationWebcams, translations }) => {

    const className = 'SkiingWebcam';
    const { camId } = useParams();
    const webcam = locationWebcams[camId];
    const routerLocation = useLocation();
    const backUrl = routerLocation.pathname.substring(0, routerLocation.pathname.lastIndexOf('/'))

    return (
        <div className="app-content SkiingWebcam__content">
        <div className="activity-i__back">
          <Link to={backUrl} className="activity-i__back-link">
            {translations.location.content.backLink}
          </Link>
        </div>
            <iframe
                className={`${className}__iframe`}
                title={webcam.name}
                src={webcam.iframeUrl}
            />
        </div>
    );
};

export default SkiingWebcam;
