import React from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { updateUrlParameter } from 'helpers/urlHelper';
import NewlineToBr from '../../../components/newlineToBr/NewlineToBr';

import './activityIndividual.scss';

import ErrorComponent from 'components/error/Error';

function ActivityIndividual({ activities, translations, parentPage, tripId, trip, locationId }) {
  let { activity } = useParams();

  if (!activities || !Object.keys(activities).length) {
    return (
      <ErrorComponent useLogo={false} source="ActivityIndividual Activities" />
    );
  }

  let activityData = null;

  activities.schedule.forEach((day) => {
    day.list.forEach((a) => {
      if (a.id === activity) {
        activityData = a;
      }
    });
  });

  if (!activityData) {
    if (tripId) {
      return <Redirect to={`/activity/${tripId}`} />
    }
    return <ErrorComponent useLogo={false} source="ActivityIndividual Data" translations={translations} />;
  }

  const newTime = activityData.newTime ? (
    <div className="activity-i__meta-info">
      {translations.meta.timeChanged.replace('{0}', activityData.time)}
    </div>
  ) : null;

  const isAfterDeadline = () => {
    if (activityData.deadlineDate) {
      const deadline = new Date(activityData.deadlineDate);
      const now = new Date();

      if (now > deadline) {
        return true;
      }
    }
    return false;
  }

  const isInBookingRange = () => {

    if (!trip) {
      return false;
    }
    else{

      if (trip.location.id !== locationId) {
        return false;
      }
      else {
        const start = new Date(trip.startDate), end = new Date(trip.endDate);

        if (activityData.datetime > start && activityData.datetime < end){
          return true;
        }
        else{
          return false;
        }
      }
    }

  }

  return (
    <div className="activities-content">
      <div
        className="activity-i__hero"
        style={{ backgroundImage: `url(${activityData.image})` }}
      >
        <div className="app-content no-padding activity-i__hero-content">
          <h1 className="activity-i__hero-title">{activityData.title}</h1>
        </div>
      </div>
      <div className="app-content activity-i__content">
        <div className="activity-i__back">
          <Link to={parentPage} className="activity-i__back-link">
            {translations.backToList}
          </Link>
        </div>
        <div className="activity-i__meta">
          <div className="activity-i__meta-line">
            <div className="activity-i__meta-label">
              {translations.meta.date}:
            </div>
            <div className="activity-i__meta-value">{activityData.date}</div>
          </div>
          <div className="activity-i__meta-line">
            <div className="activity-i__meta-label">
              {translations.meta.time}:
            </div>
            <div className="activity-i__meta-value">
              {activityData.newTime || activityData.time} {newTime}
            </div>
          </div>
          <div className="activity-i__meta-line">
            <div className="activity-i__meta-label">
              {translations.meta.place}:
            </div>
            <div className="activity-i__meta-value">{activityData.place}</div>
          </div>
        </div>
        {activityData.usps &&
        <ul className="activity-i__usps">
          {activityData.usps.map((x) => (
            <li key={x} className="activity-i__usp">
              {x}
            </li>
          ))}
        </ul>
      }
        {activityData.description &&
          <div className="activity-i__description">
            <NewlineToBr text={activityData.description} />
          </div>
        }
        {activityData.canceled && 
          <div className="btn btn--alert">{translations.canceled}</div>
        }
        {activityData.isBookable && isInBookingRange() && (
          <div className="activity-i__book">
            <div className="activity-i__price">{activityData.price}</div>
            {
              console.log('DATA:', activityData)

            }
            {activityData.deadline && <div className="activity-i__deadline">{translations.bookDeadline || ''} { activityData.deadline.toISOString().slice(0,10)} { activityData.deadline.toISOString().slice(11,16)}</div>}
            {!isAfterDeadline() && activityData.isBookable ?
              <a className="activity-i__cta" href={updateUrlParameter(activityData.bookingLink, 'redirectUrl', window.location.href)}>
                {translations.cta}
              </a>
              :
              <div className="activity-i__cta activity-i__cta--disabled">
                {translations.ctaNoAction || ''}
              </div>
              }
          </div>
        )}
      </div>
    </div>
  );
}

ActivityIndividual.propTypes = {
  translations: PropTypes.object,
  activities: PropTypes.object,
  parentPage: PropTypes.string,
};

export default ActivityIndividual;
