import React from 'react';
import { PropTypes } from 'prop-types';

import './hikingFramework.scss';

import SecondaryNavigation from 'components/secondaryNavigation/SecondaryNavigation';

function HikingFramework({
  locationId,
  translations,
  location,
  lastLevelPath,
  children,
}) {
  if (!translations || !location) {
    return null;
  }

  const subpages = [
    {
      name: translations.tabs.lifts,
      url: `/hiking/${locationId}`,
      isActive: lastLevelPath !== 'weather' && lastLevelPath !== 'webcam',
    },
    /*
    {
      name: translations.tabs.weather,
      url: `/hiking/${locationId}/weather`,
      isActive: lastLevelPath === 'weather',
    },
    */
    {
      name: translations.tabs.webcam,
      url: `/hiking/${locationId}/webcam`,
      isActive: lastLevelPath === 'webcam',
    },
  ];

  return (
    <div className="hiking-content">
      <SecondaryNavigation options={subpages} />

      {children}
    </div>
  );
}

HikingFramework.propTypes = {
  translations: PropTypes.object,
  location: PropTypes.object,
};

export default HikingFramework;
