const {translate} = require('../translations/translation')
const {marketAPI, getMarketAPI} = require('../helpers/marketHelper')

const getPaymentStatus = (summary) => {

    if (summary.leftToPayDeposit > 0) return 'DepositNotPayed';
    if (summary.leftToPay > 0) return 'NotPayed';
    return summary.leftToPay === 0 ? 'AllPayed' : null
}

const getDurationDate = (start, end) => {
    return `${formatDate(start, shortDateOptions)} - ${formatDate(end, shortDateOptions)}`
}

const TripStatus = {
    Cancelled : 'Cancelled'
}

const getDuration = (timeSpan) =>{
    if (timeSpan == null) return null;

    const spans = timeSpan.split(":");
    const hours = spans[0];
    const minutes = spans[1];

    return `${ parseInt(hours) !== 0 ? parseInt(hours) + ' h' : '' }${ parseInt(minutes) !== 0 ? ' ' + parseInt(minutes) + ' m' : '' }`

}

const longDateOptions = { weekday : 'long', day: 'numeric', month: 'long', year : 'numeric'};
const shortDateOptions = { day: 'numeric', month: 'short' };


const formatDate = (d, options = {}) => {
    //return new Date(d).toLocaleDateString('sv-SE', options)
    if (d){
        return d.split('T')[0]
    }
    else return null;
}

const formatTime = (t, timezone) => {
    const options = { hour : '2-digit', minute: '2-digit' }
    //return new Date(t).toLocaleTimeString('sv-SE', options)
    return t.substr(11,5)
}

const getAddonType = (type, locale) => {
    switch (type)
    {
        case 'None':
            return translate(locale, 'trip.addons.addonNoneCategory');
        case 'Ski':
        case 'SkiRental':
        case 'SkiPass':
        case 'SkiSchool':
            return translate(locale, 'trip.addons.addonSkiCategory');
        case 'Flight':
        case 'FlightMeal':
        case 'Transfer':
        case 'SkiLuggage':
        case 'TravelInsurance':
            return translate(locale, 'trip.addons.addonFlightCategory');
        case 'Hiking':
            return translate(locale, 'trip.addons.addonHikingCategory');
        case 'Excursion':
            return translate(locale, 'trip.addons.addonExcursionCategory');
        default:
            return 'Missing type ' + type
    }
}


const addonsMap = (addons, locale) => {

    let groups = {};
    for (let i = 0; i < addons.length; i++){
        var a = addons[i];
        let group = {
            title : getAddonType(a.type, locale),
            displayStyle : a.type == 'Excursion' ? 'wide' : 'slim',
            addons : a.articles.filter((af) => !af.allReserved).map((ad) => {  
                    return {
                        id : ad.id, 
                        deadline: ad.bookingDueDate ? `${formatDate(ad.bookingDueDate, longDateOptions) } ${formatTime(ad.bookingDueDate) }`  : null,
                        image: ad.imageUrl,
                        name: ad.description,
                        description: ad.usps,
                        url: a.url,
                    }    
            })
        }
        if (group.addons.length > 0){
            if (groups[group.title]){
                groups[group.title].addons = groups[group.title].addons.concat(group.addons)
            }
            else{
                groups[group.title] = group;
            }    
        }
    }

    const filteredGroup = Object.values(groups);

    return filteredGroup;
}

const getCTA = (trip, paymentStatus) => {

    const marketURL = getMarketAPI(trip.summary.marketId);
    const hostUrl = `https://${marketURL}`;

    var status = null
    switch (paymentStatus)
    {
        case 'DepositNotPayed':
            status = {
                link : `${hostUrl}/reservation/${trip.summary.id}?status=newDepositPayment`,
                text : translate(trip.summary.languageId, 'trip.cta.depositLeftText').replace('{0}', trip.summary.depositFee),
            };
            break;
        case 'NotPayed':
            status = {
                text : translate(trip.summary.languageId, 'trip.cta.notPayedText'),
                link : `${hostUrl}/reservation/${trip.summary.id}?status=newFullPayment`
            };
            break;
        case 'AllPayed':
            status = {
                text : translate(trip.summary.languageId, 'trip.cta.allPayedText')
            };
            break
        default:
            status = {};

    }

    return status;

}


export const tripMap = (trip, market) =>{

    const paymentStatus = getPaymentStatus(trip.summary);

    var flightOut, flightHome = {
    }

    for (let i = 0; i < trip.summary.charter.flights.length; i++) {
        const f = trip.summary.charter.flights[i];
        if (f.direction === 'Outward'){
            
            flightOut = {
                date : formatDate(f.departureDateTime, longDateOptions),
                flight : `${f.airlineName} / ${f.flightNumber}`,
                duration: getDuration(f.journeyTime),
                departure : {
                    airport : f.originAirport,
                    time: formatTime(f.departureDateTime)
                },
                arrival : {
                    airport : f.destinationAirport,
                    time: formatTime(f.arrivalDateTime)
                }
            }
        }
        else{
            flightHome = {
                date : formatDate(f.departureDateTime, longDateOptions),
                flight : `${f.airlineName} / ${f.flightNumber}`,
                duration: getDuration(f.journeyTime),
                departure : {
                    airport : f.originAirport,
                    time: formatTime(f.departureDateTime)
                },
                arrival : {
                    airport : f.destinationAirport,
                    time: formatTime(f.arrivalDateTime)
                }
            }
        }
    }

    console.log(trip.summary)

    return {
        id : trip.summary.id,
        contact : {
            firstName : trip.summary.payingCustomer.firstName,
            lastName : trip.summary.payingCustomer.lastName,
            email : trip.summary.payingCustomer.emailAddress,
            phoneNumber : trip.summary.payingCustomer.cellPhoneNumber
        },
        isSummer : trip.summary.season === 'Summer',
        isPaid : paymentStatus == "AllPayed",
        isCancelled : trip.TripStatus == 'Cancelled',
        durationDate : getDurationDate(trip.summary.firstTravelDate, trip.summary.lastTravelDate),
        startDate : formatDate(trip.summary.firstTravelDate), //?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
        endDate : formatDate(trip.summary.lastTravelDate), //?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
        summary : {
            bookingNumber : trip.summary.reservationCode,
            bookingDate : formatDate(trip.summary.firstConfirmationDate), //?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
            bookingPerson : `${trip.summary.payingCustomer.firstName} ${trip.summary.payingCustomer.lastName}`,
            bookingPrice : trip.summary.price, // .ToString(trip.Market),
            bookingFee : trip.summary.depositFee, //.ToString(trip.Market),
            bookingFeeHelp : paymentStatus == 'AllPayed' || paymentStatus == 'NotPayed' ? null : formatDate(trip.summary.depositDueDate), // ?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
            bookingPayment : trip.FinalPayment, //.ToString(trip.Market),
            bookingPaymentHelp : paymentStatus == 'AllPayed' ? null : formatDate(trip.summary.fullPaymentDueDate), // ?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
            leftToPay : trip.summary.leftToPay, //.ToString(trip.Market),
            paid : trip.summary.paid, //.ToString(trip.Market),
            depositDueDate : formatDate(trip.summary.depositDueDate), //?.ToUniversalTime(),
            paymentDueDate : formatDate(trip.summary.lastPaymentDate), //?.ToUniversalTime()
            paymentStatus : paymentStatus
        },
        market : trip.summary.marketId, //.ToString(),
        bookingConfirmation : getPaymentStatus(trip.summary) === 'AllPayed' ? {
            /*
            Text : paymentStatus == 'AllPayed'
                    ? translations.Trip?.bookingConfirmation?.IsPaid
                    : translations.Trip?.bookingConfirmation?.NotPaid,*/
            url :  `https://${getMarketAPI(trip.summary.marketId)}/api/v1/reservations/ticket.pdf?reservationId=${trip.summary.id}`
            
        } : getPaymentStatus(trip.summary),
        travellers : trip.summary.travelers.map(traveler => (
        {
            name : `${traveler.firstName} ${traveler.lastName}`,
            dateOfBirth : formatDate(traveler.birthDate), // ?.ToString("yyyy-MM-dd"),
            gender : traveler.genderCode === 'F' ? translate(trip.summary.languageId, 'trip.travellers.genderFemale') : translate(trip.summary.languageId, 'trip.travellers.genderMale') ,
            type :  traveler.age && traveler.age < 18 ? translate(trip.summary.languageId, 'trip.travellers.typeChild') : translate(trip.summary.languageId, 'trip.travellers.typeAdult'), //GetTypeTranslation(traveler.Type),
            room : traveler.roomDescription
        })),
        destination : {
            destination : trip.summary.hotels && trip.summary.hotels.length > 0 ? trip.summary.hotels[0].destinationDescription : '',
            hotel : trip.summary.hotels && trip.summary.hotels.length > 0 ? trip.summary.hotels[0].name : '',
            checkIn : trip.summary.hotels && trip.summary.hotels.length > 0 ? formatDate(trip.summary.hotels[0].checkinDate) : '', //?.ToString("yyyy-MM-dd"),
            checkOut : trip.summary.hotels && trip.summary.hotels.length > 0 ? formatDate(trip.summary.hotels[0].checkoutDate): '', // ?.ToString("yyyy-MM-dd"),
            package : `${trip.summary.packages[0].shortInfo}, ${trip.summary.packages[0].info}`
        },
        location : {
            id : trip.summary.search.destinationId.toLowerCase() + (trip.summary.season === 'Winter' ? '_w' : '_s'),
            name :trip.summary.hotels && trip.summary.hotels.length > 0 ? trip.summary.hotels[0].destinationDescription : ''
        },
        flight: {
            fromHome : flightOut,
            toHome: flightHome
        },
        addons: addonsMap(trip.addons, trip.summary.languageId),
        cta : getCTA(trip, paymentStatus),
        image : trip.image,
        type: trip.summary.search.travelType
    }

    
}



/**
 
            Date = fromFlight.DepartureDateTime?.ToString("dddd dd MMMM yyyy", new CultureInfo(LanguageMapper.Map(trip.Market))),
            Flight = $"{fromFlight.AirLineName} / {fromFlight.FlightNumber}",
            Duration = GetDuration(fromFlight.FlightDuration),
            Departure = new DepartureArrivalViewModel
            {
                Airport = fromFlight.DepartureAirport,
                Time = fromFlight.DepartureDateTime?.ToString("HH:mm")
            },
            Arrival = new DepartureArrivalViewModel
            {
                Airport = fromFlight.ArrivalAirport,
                Time = fromFlight.ArrivalDateTime?.ToString("HH:mm")
            }

*/
/*

public TripViewModel Get(Trip trip, IEnumerable<Addon> addons, IEnumerable<Activity> activities)
        {
            var cultureInfo = new CultureInfo(LanguageMapper.Map(trip.Market));
            var hostUrl = GetbookingHostUrl(trip.Market);
            if (hostUrl == null) throw new Exception("Could not get host url.");
            var langFilePath = Path.Combine(AppDomain.CurrentDomain.BaseDirectory,
                $"PWA/Lang/translations.{LanguageMapper.Map(trip.Market)}.json");
            var translations = _jsonReader.GetJsonObject<Translations>(langFilePath);
            var paymentStatus = trip.GetPaymentStatus();
            var hosts = _siteDefinitionRepository.List().FirstOrDefault()?.Hosts;
            var currentHost = hosts?.FirstOrDefault(x => x.Language.Equals(cultureInfo) && x.Type == HostDefinitionType.Primary);
            return new TripViewModel
            {
                Id = trip.Id,
                Contact = new ContactViewModel
                {
                    FirstName = trip.booking.FirstName,
                    LastName = trip.booking.LastName,
                    Email = trip.booking.Email,
                    PhoneNumber = trip.booking.PhoneNumber
                },
                IsSummer = trip.SeasonType == SeasonType.Summer,
                Image = trip.SeasonType == SeasonType.Summer
                    ? _qbankIdsSummer.TryGetValue(trip.Hotels.First().LocationId.ToLower(), out var qbankIdSummer)
                        ? QbankMediaContextFactory.GetUrlFromQBankId(qbankIdSummer, _ => $"_{Global.QbankApplicationSettings.MediaFormats.ImageSlider}")
                        : null
                    : _qbankIdsWinter.TryGetValue(trip.Hotels.First().LocationId.ToLower(), out var qbankId)
                        ? QbankMediaContextFactory.GetUrlFromQBankId(qbankId, _ => $"_{Global.QbankApplicationSettings.MediaFormats.ImageSlider}")
                        : null,
                IsPaid = paymentStatus == PaymentStatus.AllPayed,
                IsCancelled = trip.TripStatus == TripStatus.Cancelled,
                DurationDate = GetDurationDate(),
                StartDate = trip.booking.StartDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                EndDate = trip.booking.EndDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                Summary = new SummaryViewModel
                {
                    bookingNumber = trip.booking.bookingNumber,
                    bookingDate = trip.booking.bookingDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                    bookingPerson = $"{trip.booking.FirstName} {trip.booking.LastName}",
                    bookingPrice = trip.TotalPrice.ToString(trip.Market),
                    bookingFee = trip.DepositFee.ToString(trip.Market),
                    bookingFeeHelp = paymentStatus == PaymentStatus.AllPayed || paymentStatus == PaymentStatus.NotPayed ? null : trip.DepositFeeDueDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                    bookingPayment = trip.FinalPayment.ToString(trip.Market),
                    bookingPaymentHelp = paymentStatus == PaymentStatus.AllPayed ? null : trip.FinalPaymentDueDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                    LeftToPay = trip.AmountLeftToPay.ToString(trip.Market),
                    Paid = trip.Paid.ToString(trip.Market),
                    DepositDueDate = trip.DepositFeeDueDate?.ToUniversalTime(),
                    PaymentDueDate = trip.FinalPaymentDueDate?.ToUniversalTime()
                },
                Market = trip.Market.ToString(),
                bookingConfirmation = new bookingConfirmationViewModel
                {
                    Text = paymentStatus == PaymentStatus.AllPayed
                           ? translations.Trip?.bookingConfirmation?.IsPaid
                           : translations.Trip?.bookingConfirmation?.NotPaid,
                    Url = $"{hostUrl}/api/reservations/ticket.pdf?reservationId={trip.Id}"
                },
                Travellers = trip.Travellers.Select(traveler => new TravellerViewModel
                {
                    Name = $"{traveler.FirstName} {traveler.LastName}",
                    DateOfBirth = traveler.BirthDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                    Gender = GetGenderTranslation(traveler.Gender),
                    Type = GetTypeTranslation(traveler.Type),
                    Room = traveler.Room
                }),
                Destination = new DestinationViewModel
                {
                    Destination = trip.Hotels.First().LocationName,
                    Hotel = trip.Hotels.First().Name,
                    CheckIn = trip.Hotels.First().CheckInDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                    CheckOut = trip.Hotels.First().CheckOutDate?.ToString("yyyy-MM-dd", CultureInfo.InvariantCulture),
                    Package = $"{trip.Packages.First().Name}, {trip.Packages.First().Includes}"
                },
                Location = new BasicLocationViewModel
                {
                    Id = trip.GetLocationSeasonId(),
                    Name = trip.Hotels.First().LocationName
                },
                Cta = GetCallToActionViewModel(),
                Includes = addons.Where(addon => addon.Purchased).Select(addon => new IncludesViewModel
                {
                    Name = addon.Name,
                    TravelerCount = $"{addon.Travelers?.Count(x => x.Reserved)}",
                    Cost = addon.Price != 0 ? $"{addon.Price.ToString(trip.Market)}" : null,
                    EditUrl = addon.Url
                }),
                Addons = addons.Where(addon => !addon.AllTravelersPurchased).GroupBy(x => GetAggregatedType(x.Type))
                .Select(x => new AddonCategoryViewModel
                {
                    Title = GetAddonTypeTitle(x.Key, translations.Trip.Addons),
                    DisplayStyle = x.Key == AggregatedAddonType.Excursion ? "wide" : "slim",
                    Addons = x.Select(addon => new AddonViewModel
                    {
                        Name = addon.Name,
                        Url = addon.Url,
                        Description = addon.Description,
                        Duration = addon.DurationDays == 0
                                ? null
                                : addon.DurationDays > 1
                                    ? Format(translations.Trip?.Addons?.DurationDays, addon.DurationDays)
                                    : Format(translations.Trip?.Addons?.DurationDay, addon.DurationDays),
                        Deadline = addon.Deadline?.ToString("HH:mm d/M", CultureInfo.InvariantCulture),
                        Image = addon.ImageUrl
                    })
                }),
                Activities = new ActivitiesViewModel
                {
                    Hero = new ActivitiesHeroViewModel
                    {
                        Heading = translations.Trip?.Activities?.Heading,
                        Description = translations.Trip?.Activities?.Description,
                        Image = trip.SeasonType == SeasonType.Summer
                            ? QbankMediaContextFactory.GetUrlFromQBankId(ScheduleHeroIdSummer, _ => $"_{Global.QbankApplicationSettings.MediaFormats.ImageSlider}")
                            : QbankMediaContextFactory.GetUrlFromQBankId(ScheduleHeroIdWinter, _ => $"_{Global.QbankApplicationSettings.MediaFormats.ImageSlider}")
                    },
                    Schedule = GetScheduleViewModel()
                },
                Flight = GetFlightViewModel(),
                AfterTripArea = trip.booking.EndDate?.Date < DateTime.UtcNow ? GetAfterTripArea(trip.SeasonType, currentHost?.Url?.ToString()) : null
            };
            string GetDurationDate()
                => $"{trip.booking.StartDate?.Day} {trip.booking.StartDate?.ToString("MMMM", cultureInfo).Substring(0, 3)}. – {trip.booking.EndDate?.Day} {trip.booking.EndDate?.ToString("MMMM", cultureInfo).Substring(0, 3)}.";

            string GetGenderTranslation(Gender gender)
            {
                switch (gender)
                {
                    case Gender.Male: return translations.Trip?.Travellers?.GenderMale;
                    case Gender.Female: return translations.Trip?.Travellers?.GenderFemale;
                    default: return null;
                }
            }

            string GetTypeTranslation(AgeGrading ageGrading)
            {
                switch (ageGrading)
                {
                    case AgeGrading.Adult: return translations.Trip?.Travellers?.TypeAdult;
                    case AgeGrading.Child: return translations.Trip?.Travellers?.TypeChild;
                    default: return null;
                }
            }

            //string GetIncludesName(AddonType addonType)
            //{
            //	switch (addonType)
            //	{
            //		case AddonType.SkiSchool: return translations.Trip?.Includes?.SkiSchool;
            //		case AddonType.Excursion: return translations.Trip?.Includes?.Excursion;
            //		case AddonType.SkiRental: return translations.Trip?.Includes?.SkiRental;
            //		case AddonType.SkiPass: return translations.Trip?.Includes?.SkiPass;
            //		case AddonType.FlightMeal: return translations.Trip?.Includes?.FlightMeal;
            //		case AddonType.SkiLuggage: return translations.Trip?.Includes?.SkiLuggage;
            //		case AddonType.Transfer: return translations.Trip?.Includes?.Transfer;
            //		case AddonType.TravelInsurance: return translations.Trip?.Includes?.TravelInsurance;
            //		case AddonType.Hiking: return translations.Trip?.Includes?.Hiking;
            //		default: return null;
            //	}
            //}

            CallToActionViewModel GetCallToActionViewModel()
            {
                switch (paymentStatus)
                {
                    case PaymentStatus.DepositNotPayed:
                        return new CallToActionViewModel
                        {
                            Text = Format(translations.Trip?.Cta?.DepositLeftText, trip.DepositFee.ToString(trip.Market)),
                            Link = $"{hostUrl}/reservation/{trip.Id}?status=newDepositPayment"
                        };
                    case PaymentStatus.NotPayed:
                        return new CallToActionViewModel
                        {
                            Text = translations.Trip?.Cta?.NotPayedText,
                            Link = $"{hostUrl}/reservation/{trip.Id}?status=newFullPayment"
                        };
                    case PaymentStatus.AllPayed:
                        return new CallToActionViewModel
                        {
                            Text = translations.Trip?.Cta?.AllPayedText
                        };
                    default:
                        return null;
                }
            }

            IEnumerable<ScheduleViewModel> GetScheduleViewModel()
                => activities
                    .GroupBy(activity => activity.Date.Date)
                    .Select(group => new ScheduleViewModel
                    {
                        Title = group.Key.ToString("dddd d MMMM", cultureInfo).ToUpper(),
                        List = group.Select(activity => new ActivityViewModel
                        {
                            Id = activity.Id,
                            Title = activity.Title,
                            Time = activity.Date.ToString("HH:mm"),
                            Date = activity.Date.ToString("dddd d MMMM", cultureInfo).FirstLetterToUpperCase(),
                            Place = activity.Location,
                            Image = activity.ImageUrl,
                            Description = activity.Description,
                            IsBookable = !activity.Canceled && !string.IsNullOrEmpty(activity.bookingUrl),
                            bookingLink = activity.bookingUrl,
                            Price = activity.Price.ToString(trip.Market),
                            Deadline = activity.DueDate?.ToString("yyyy-MM-dd HH:mm", CultureInfo.InvariantCulture),
                            DeadlineDate = activity.DueDate,
                            Canceled = activity.Canceled,
                            Usps = activity.Usps
                        })
                    });

            //TODO: handle multiple flights, see user story 396
            FlightViewModel GetFlightViewModel()
            {
                var fromFlight = trip.Flights.FirstOrDefault(flight => flight.Direction == FlightDirection.FromHome);
                var toFlight = trip.Flights.FirstOrDefault(flight => flight.Direction == FlightDirection.ToHome);
                if (fromFlight == null && toFlight == null) return null;
                return new FlightViewModel
                {
                    FromHome = fromFlight == null
                        ? null
                        : new FlightInfoViewModel
                        {
                            Date = fromFlight.DepartureDateTime?.ToString("dddd dd MMMM yyyy", new CultureInfo(LanguageMapper.Map(trip.Market))),
                            Flight = $"{fromFlight.AirLineName} / {fromFlight.FlightNumber}",
                            Duration = GetDuration(fromFlight.FlightDuration),
                            Departure = new DepartureArrivalViewModel
                            {
                                Airport = fromFlight.DepartureAirport,
                                Time = fromFlight.DepartureDateTime?.ToString("HH:mm")
                            },
                            Arrival = new DepartureArrivalViewModel
                            {
                                Airport = fromFlight.ArrivalAirport,
                                Time = fromFlight.ArrivalDateTime?.ToString("HH:mm")
                            }
                        },
                    ToHome = toFlight == null
                        ? null
                        : new FlightInfoViewModel
                        {
                            Date = toFlight.DepartureDateTime?.ToString("dddd dd MMMM yyyy", new CultureInfo(LanguageMapper.Map(trip.Market))),
                            Flight = $"{toFlight.AirLineName} / {toFlight.FlightNumber}",
                            Duration = GetDuration(toFlight.FlightDuration),
                            Departure = new DepartureArrivalViewModel
                            {
                                Airport = toFlight.DepartureAirport,
                                Time = toFlight.DepartureDateTime?.ToString("HH:mm")
                            },
                            Arrival = new DepartureArrivalViewModel
                            {
                                Airport = toFlight.ArrivalAirport,
                                Time = toFlight.ArrivalDateTime?.ToString("HH:mm")
                            }
                        }
                };
            }
        }

        */