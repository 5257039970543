export const no = 
{
  "global": {
    "title": "Alpereiser",
    "loginText": "Du må oppgi reisen din først, dette er enklest for deg gjennom mine sider eller bestillingsbekreftelsen.",
    "navigation": {
      "trip": "Min reise",
      "activity": "Ukeprogram",
      "skiing": "Ski",
      "hiking": "Vandring",
      "location": "Stedet"
    },
    "login": {
      "form": {
        "bookingRefLabel": "Bestillingsnummer ",
        "bookingRefPlaceholder": "00000000",
        "emailLabel": "E-mail",
        "emailPlaceholder": "du@exempel.se",
        "submitLabel": "Login",
        "errorMessage": "Noe gikk galt, sjekk om du har lagt inn riktig informasjon. "
      }
    }
  },
  "trip": {
    "hero": {
      "greeting": "Hei {0}",
      "header": "Min reise",
      "message": "Hei {0}, velkommen til reisen din",
      "afterTripMessage": "Takk for at du reiser med oss {0}. Velkommen igjen!",
      "daysLeft": "dager til avreise",
      "title": "Du reiser til {0}"
    },
    "flight": {
      "header": "Reiseinformasjon",
      "travelTime": "Reisetid",
      "addSki": "Legg til ski frakt",
      "luggage": "{0} innsjekket bagasje per person maks {0} er inkludert",
      "skiLuggage": "1 par ski + staver, 1 par skistøvler, 1 hjelm - maks {0}"
    },
    "summary": {
      "header": "Oppsummering",
      "header2": "Betaling",
      "isPaid": "Reisen din er betalt",
      "isCancelled": "Reisen din er kansellert",
      "bookingNumber": "Bestillingsnummer",
      "bookingDate": "Bestillingsdato",
      "bookingPerson": "Bestilles av",
      "bookingPrice": "Totalpris",
      "bookingFee": "Registreringsavgift ",
      "bookingFeeHelp": "Siste betalingsfrist {0}",
      "bookingPayment": "Sluttbetaling",
      "bookingPaymentHelp": "Betalingsfrist {0}",
      "hint1": "Når reisen er betalt i sin helhet kan du laste ned din billett her",
      "hint2": "I neste steg velger du om du vil betale påmeldingsavgiften eller hele reisen",
      "email": "E-post",
      "phoneNumber": "Telefon",
      "paid": "Betalt så langt",
      "leftToPay": "Igjen å betale"
    },
    "tabs": {
      "booking": "Bestilling",
      "faq": "FAQ",
      "addons": "Tilvalg"
    },
    "travellers": {
      "header": "Reisende",
      "name": "Navn",
      "room": "Rom",
      "type": "Type",
      "typeAdult": "Voksen",
      "typeChild": "Barn",
      "dateOfBirth": "Fødselsdato",
      "gender": "Kjønn",
      "genderMale": "Mann",
      "genderFemale": "Kvinne",
      "notice": "Vil du legge til flere reisende? Kontakt oss på info@alpereiser.no eller <span>21 31 49 00</span>"
    },
    "destination": {
      "header": "Reisemål og hotell",
      "destination": "Reisemål",
      "hotel": "Hotell",
      "checkIn": "Sjekk inn",
      "checkOut": "Sjekk ut",
      "package": "Pakke",
      "linkLabel": "Rom og reisende",
      "linkText": "Se lenger ned"
    },
    "includes": {
      "header": "Mine tilvalg",
      "seeAll": "Se flere alternativer",
      "edit": "Rediger",
      "included": "Inkludert",
      "skiSchool": "Skiskole",
      "excursion": "Utflukt",
      "skiRental": "Skileie",
      "skiPass": "Heiskort",
      "flightMeal": "Flymat",
      "skiLuggage": "Skibagasje",
      "transfer": "Transfer",
      "travelInsurance": "Reiseforsikring",
      "hiking": "Vandring",
      "travelers": "{0} st",
      "pricing": "á {0}"
    },
    "addons": {
      "deadline": "Bestill senest {0}",
      "order": "Legg til",
      "durationDays": "{0} dager",
      "durationDay": "{0} dag",
      "addonSkiCategory": "Ski",
      "addonFlightCategory": "Fly",
      "addonHikingCategory": "Vandring",
      "addonExcursionCategory": "Andre aktiviteter",
      "addonNoneCategory": "Andre"
    },
    "cta": {
      "depositLeftText": "Sikre reisen - betal {0} nå",
      "notPayedText": "Sluttbetal reisen din",
      "allPayedText": "Reisen er betalt",
      "logout": "Logg deg ut"
    },
    "activities": {
      "heading": "Ukens aktiviteter",
      "description": "Vi i STS Alpreiser organiserer mange utflukter og arrangementer sammen med guider og andre gjester som du kan ta del av under reisen."
    },
    "bookingConfirmation": {
      "isPaid": "Last ned billetten",
      "notPaid": "Last ned bestillingsbekreftelsen"
    }
  },
  "location": {
    "contact": {
      "title": "Kontakt oss",
      "viewMore": "Vis mer",
      "leader": "Plassansvarlig",
      "backLink": "Tilbak til stedet"
    },
    "content": {
      "rootPage": "Stedet",
      "openInMaps": "Vis på kart",
      "backLink": "Tilbak",
      "tripAdvisor": {
        "memberHeader": "Medlemsvurdering",
        "five": "Utmerket",
        "four": "Veldig bra",
        "three": "Middels",
        "two": "Dårlig",
        "one": "Veldig dårlig",
        "overviewHeader": "Totalvurdering",
        "numRatings": "vurderinger av TripAdvisors besøkere",
        "subratinglocation": "Beliggenhet",
        "subratingsleep": "Sovekvalitet",
        "subratingroom": "Rom",
        "subratingservice": "Service",
        "subratingvalue": "Standard",
        "subratingcleanliness": "Renslighet",
        "subratingfood": "Mat",
        "subratingatmosphere": "Atmosfære"
      }
    }
  },
  "activity": {
    "bookPlace": "Bestill",
    "backToList": "Tilbak til listen",
    "bookDeadline": "Bestill senest",
    "meta": {
      "date": "Datum",
      "time": "Tid",
      "timeChanged": "(flyttet fra {0})",
      "place": "Sted"
    },
    "cta": "Bestill",
    "ctaNoAction": "Ikke bookbar",
    "canceled": "Kansellert",
    "disclaimer": "Vær obs på at planen er foreløpig og kan bli endret pga været og andre forhold."
  },
  "excursions": {
    "skiing": {
      "tabs": {
        "lifts": "Heissystem",
        "weather": "Vær og snø",
        "webcam": "Webcamera"
      }
    },
    "hiking": {
      "tabs": {
        "lifts": "Vandring",
        "weather": "Vær",
        "webcam": "Webcamera"
      }
    },
    "weather": {
      "header": "{0} dagers værvarsel",
      "weatherTypes": {
        "rain": "Regn",
        "rainshowers": "regnskurer",
        "snowshowers": "Snøskurer",
        "snow": "Snø",
        "cloudy": "overskyet",
        "partlycloudy": "Delvis skyet",
        "sun": "Sol",
        "sleet": "Snøblandet regn/Sludd",
        "sleetshowers": "Snøblandet regnbyger/Sludd",
        "fog": "Tåke",
        "fair": "Klart"
      },
      "maxTop": "Min-/maks topp",
      "maxValley": "Min-/maks dal",
      "minTop": "Min-/maks topp",
      "minValley": "Min-/maks dal",
      "wind": "Vind",
      "snow": "Snø"
    },
    "snow": {
      "header": "Snørapporter",
      "snowTop": "Snødybde toppen av skibakken",
      "snowValley": "Snødybde dal",
      "lastSnow": "Siste snøfall",
      "snowLastAmount": "Snøfall siste {0} dager",
      "openLifts": "Åpne heiser"
    }
  }
}