import React from 'react';
import { PropTypes } from 'prop-types';

import './hikingWebcams.scss';
import LocationWebcams from 'components/locationWebcams/LocationWebcams';

function HikingWebcams({ locationWebcams, htmlWebcams, translations }) {
  if (!locationWebcams) {
    // TODO: Return empty state?
    return null;
  }

  return (
    <div>
      <LocationWebcams
        webcams={locationWebcams}
        translations={translations}
        htmlWebcams={htmlWebcams}
      />
    </div>
  );
}

HikingWebcams.propTypes = {
  translations: PropTypes.object,
  locationWebcams: PropTypes.arrayOf(PropTypes.object),
  htmlWebcams: PropTypes.arrayOf(PropTypes.string),
};

export default HikingWebcams;
