import React from 'react';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './Trip.scss';

import TripFramework from './shared/tripFramework';
import TripNotifications from './shared/tripNotifications';
import TripBooking from './tripBooking/TripBooking';
import TripAddons from './tripAddons/TripAddons';
import TripAnswers from './tripAnswers/TripAnswers';
import ErrorComponent from 'components/error/Error';
import LoaderComponent from 'components/loader/Loader';

function Trip({ setTripId, translations, trip, settings }) {
  let { id } = useParams();
  const routerLocation = useLocation();
  const routerList = routerLocation.pathname.split('/');
  const lastLevelPath = routerList[routerList.length - 1];
  const tripId = id;
  const tripTranslations = translations && translations.trip;

  if (!trip) {
    setTripId && setTripId(String(tripId));

    return <LoaderComponent />;
  }

  if (!tripTranslations || !trip || !Object.keys(trip).length) {
    return <ErrorComponent useLogo={false} source="Trip" />;
  }

  return (
    <div className="trip-content">
      <Switch>
        <Route exact path="/trip/:id">
          <TripFramework
            translations={tripTranslations}
            lastLevelPath={lastLevelPath}
            tripId={tripId}
            trip={trip}
            hideCTAMobile={false}
          >
            <TripBooking translations={tripTranslations} trip={trip} />
          </TripFramework>
        </Route>
        <Route exact path="/trip/:id/addons">
          <TripFramework
            translations={tripTranslations}
            lastLevelPath={lastLevelPath}
            tripId={tripId}
            trip={trip}
            hideCTAMobile={true}
          >
            <TripAddons translations={tripTranslations} trip={trip} />
          </TripFramework>
        </Route>
        <Route exact path="/trip/:id/faq">
          <TripFramework
            translations={tripTranslations}
            lastLevelPath={lastLevelPath}
            tripId={tripId}
            trip={trip}
            hideCTAMobile={true}
          >
            <TripAnswers settings={settings} />
          </TripFramework>
        </Route>
        <Route exact path="/trip/:id/notifications">
          <TripFramework
            translations={tripTranslations}
            lastLevelPath={lastLevelPath}
            tripId={tripId}
            trip={trip}
            hideCTAMobile={true}
            hideHero={true}
          >
            <TripNotifications tripId={tripId} />
          </TripFramework>
        </Route>
      </Switch>
    </div>
  );
}

Trip.propTypes = {
  setTripId: PropTypes.func,
  translations: PropTypes.object,
  settings: PropTypes.object,
  trip: PropTypes.object,
  fetchedLocation: PropTypes.bool,
};

export default Trip;
