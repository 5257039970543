import { getMarket } from './marketHelper';
import { MARKET_COOKIE } from 'shared/constants';

export const setPageTitle = (title) => {
  if (!title) {
    console.log('Could not set page title.');
    return;
  }

  document.title = title;
};

export const setPageMarket = (market) => {
  const marketId = getMarket();

  // Set which language to use babsed on the trip
  if (market) {
    if (marketId !== market) {
      // TODO: UPDATE API AND LOGO AND STUFFS. Redirect to correct domain?
    }

    // Update saved market id
    document.cookie = `${MARKET_COOKIE}=${market}; path=/"`;
  }
};

export const isFetched = (result) => result !== null;
export const hasData = (result) => result && result.data;
