import axios from 'axios';

export const callToAPI = (url, body) => {
  // TODO: Handle 404 without crashing the app!

  return axios
    .get(url, {
      data: body,
      params: body,
    })
    .then(function({ data: response }) {
      if (response.data) {
        return response.data;
      }

      return false;
    })
    .catch(function(error) {
      console.error(`The call to ${url} failed.`);

      return false;
    });
};
