import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './tripIncludes.scss';

function TripIncludes({ includes, translations, tripId }) {
  if (!translations) {
    return null;
  }

  const getCost = (cost) => {
    if (!cost) {
      return translations.included;
    }

    const builtCost = translations.pricing ? translations.pricing.replace('{0}', cost) : '';
    return builtCost;
  };

  const data =
    includes &&
    includes.map((inc) => { 
      const builtTravelers = translations.travelers ? translations.travelers.replace('{0}', inc.travelerCount) : '';
      return (
        <div className="trip__includes-row" key={inc.name}>
          <div className="trip__includes-title">
            <div className="trip__includes-name">{inc.name}</div>
            <a href={inc.editUrl} className="trip__includes-edit">
              {translations.edit}
            </a>
          </div>
          <div className="trip__includes-cost">
            <div className="trip__includes-prefix">
              {builtTravelers}
            </div>
            <div className="trip__includes-price">
              {getCost(inc.cost)}
            </div>
          </div>
        </div>
      );
    });

  const dataDesktop = () => (
      <table className="trip__includes-table">
        <tbody>
        {includes &&
      includes.map((inc, idx) => {
        const builtTravelers = translations.travelers ? translations.travelers.replace('{0}', inc.travelerCount) : '';
        return (
          <tr className="trip__includes-table-row" key={idx}>
            <td>{inc.name}</td>
            <td>{builtTravelers}</td>
            <td>{getCost(inc.cost)}</td>
            <td><a href={inc.editUrl} className="trip__includes-edit">
                {translations.edit}
              </a></td>

          </tr>
        )})}
        </tbody>
      </table>
    );

  return (
    <div className="trip__includes">
      {data}{dataDesktop()}
      <div className="trip__includes-cta">
      <Link to={`/trip/${tripId}/addons`} className="btn btn-primary">
          {`${translations.seeAll} +`}
        </Link>
      </div>
    </div>
  );
}

TripIncludes.propTypes = {
  includes: PropTypes.array,
  translations: PropTypes.object,
};

export default TripIncludes;
