import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  getLanguage,
  getLocale,
  getMarket,
  getMarketAPI,
} from '../../helpers/marketHelper';
import {
  fetchGlobals,
  fetchTrip,
  fetchLocation,
  //fetchWeather,
  fetchNotifications,
  setIdFromOffline,
} from 'helpers/fetchHelper';
import {
  setPageMarket,
  setPageTitle,
  isFetched,
  hasData,
} from 'helpers/setupHelper';

import { TRIP_STATUS } from 'shared/constants';
import './Framework.scss';

import Header from 'components/header/Header';
import Notification from 'components/notification/Notification';
import ErrorComponent from 'components/error/Error';
import LoaderComponent from 'components/loader/Loader';
import locations from 'components/locationSelector/locations';

export default function Framework({
  children,
  basePath,
  hideNavigation,
  tripId,
  setTripId,
  locationId,
  setLocationId,
}) {
  const [settings, setSettings] = useState(null);
  const [trip, setTrip] = useState(null);
  const [location, setLocation] = useState(null);
  //const [weather, setWeather] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [activities, setActivities] = useState(null);

  /////// Update data ///////
  useEffect(() => {
    if (!isFetched(settings)) {
      fetchGlobals(setSettings);
    }
  }, [settings]);

  useEffect(() => {
    if (!isFetched(trip) && tripId) {
      fetchTrip(tripId, setTrip);
    }
  }, [trip, tripId]);

  useEffect(() => {
    if (!isFetched(notifications) && tripId) {
      fetchNotifications(tripId, setNotifications);
    }
  }, [notifications, tripId]);

  useEffect(() => {
    if (!isFetched(location) && locationId) {
      fetchLocation(locationId, setLocation);

      setLocationName(locations[locationId]);
      if (trip && trip.activities) {
        setActivities(trip.activities);
      }
    }
  }, [location, locationId, trip]);

  /*
  useEffect(() => {
    if (!isFetched(weather) && locationId) {
      fetchWeather(locationId, setWeather);
    }
  }, [weather, locationId]);
  */

  useEffect(() => {
    if (trip && trip.activities) {
      setActivities(trip.activities);
    } else if (locationId) {
      const destination = locationId.split('_')[0].toUpperCase();
      const market = getMarket().toUpperCase();
      const languageId = getLanguage();
      const locale = getLocale();
      const marketAPI = getMarketAPI();

      const today = new Date().toLocaleDateString('sv-se');

      axios
        .get(
          `https://${marketAPI}/api/v1/query/destination/addonContract/schemeActivities?destinationId=${destination}&marketId=${market}&languageId=${languageId}&FromDate=${today}&ToDate=2025-04-30`
        )
        .then((res) => res.data.data)
        .then((data) => {
          const schedule = data.map((s) => {
            return {
              title: s.id,
              list: [
                {
                  id: s.id,
                  title: s.description,
                  time: new Date(Date.parse(s.dateTime)).toLocaleTimeString(
                    `${locale}`,
                    { hour: '2-digit', minute: '2-digit' }
                  ),
                  date: new Date(Date.parse(s.dateTime)).toLocaleDateString(
                    `${locale}`,
                    { weekday: 'long', month: 'long', day: 'numeric' }
                  ),
                  datetime: new Date(Date.parse(s.dateTime)),
                  place: s.place || s.location,
                  image: s.imageUrl,
                  description: s.info,
                  isBookable: s.articleType === 'Excursion',
                  price: s.price, // "185 kr",
                  deadline: s.dueDate ? new Date(Date.parse(s.dueDate)) : null,
                  deadlineDate: s.dueDate
                    ? new Date(Date.parse(s.dueDate))
                    : null,
                  bookingLink: `https://${marketAPI}/addons/${tripId}#Excursion`,
                  canceled: s.canceled,
                  usps: [],
                },
              ],
            };
          });

          const heroImages = {
            winter: '//www.alpresor.se/img/12413.jpg&w=1440',
            summer: '//www.alpresor.se/img/10836.jpg&w=1440',
          };

          const image = heroImages['summer'];

          const heroes = {
            se: {
              heading: 'Veckans aktiviteter',
              description:
                'Vi på STS Alpresor arrangerar massvis med utflykter och arrangemang i sällskap av våra guider och andra gäster för er att ta del av under resan.',
              image: image, // Vinter: "//www2.alpresor.se/qbank/published/12413.jpg&w=1440"  Sommar: "//www2.alpresor.se/qbank/published/10836.jpg&w=1440"
            },
            fi: {
              heading: 'Viikon aktiviteetit',
              description:
                'Me STS Alppimatkoilla järjestämme paljon retkiä ja aktiviteettejä oppaidemme ja asiakkaidemme kanssa, jonne voit osallistua matkan aikana.',
              image: image, // Sommar: "//www2.alpresor.se/qbank/published/10836.jpg&w=1440"
            },
            no: {
              heading: 'Ukens aktiviteter',
              description:
                'Vi i STS Alpreiser organiserer mange utflukter og arrangementer sammen med guider og andre gjester som du kan ta del av under reisen.',
              image: image,
            },
          };

          const _activities = {
            hero: heroes[getMarket()],
            schedule: schedule,
          };
          setActivities(_activities);
        });
    }
  }, [locationId, trip, tripId]);

  /////// Setup ///////
  if (hasData(settings)) {
    setPageTitle(settings.data.translations.global.title);
  }

  // Trip is master of which market we are on
  if (hasData(trip)) {
    setPageMarket(trip.data.market);
  }

  /////// Checks ///////
  // No settings (translations) available, no application to show
  if (isFetched(settings) && !hasData(settings)) {
    return <ErrorComponent useLogo={true} source="Framework Settings" />;
  } else if (isFetched(trip) && !hasData(trip)) {
    return <ErrorComponent useLogo={true} source="Framework Trip" />;
  }

  // Loading data
  if (!isFetched(settings)) {
    return <LoaderComponent />;
  }

  // Trip is master, but if we get location without trip, get tripId from offline
  if (!tripId && locationId) {
    setIdFromOffline('trip', setTripId);
  }

  // Trip decides which location we have
  if (hasData(trip) && !locationId) {
    setLocationId && setLocationId(String(trip.data.location.id));
  }

  // Show header only if URL's can be created, and the header actually should be visible
  // Do not include tripId here, if visiting only for location information
  const headerReady = !hideNavigation && locationId;

  // TODO: Redirect trip null to login page!

  return (
    <div>
      {headerReady && (
        <Header
          title={hasData(location) && location.data.name}
          translations={hasData(settings) && settings.data.translations}
          isSummer={hasData(trip) && trip.data.isSummer}
          tripStatus={TRIP_STATUS.during}
          basePath={basePath}
          tripId={tripId}
          locationId={locationId}
          locationName={locationName}
          trip={trip && trip.data}
          notifications={notifications && notifications.data}
        />
      )}

      <Notification
        tripId={tripId}
        notifications={notifications && notifications.data}
      />

      <main className="framework-container">
        {React.cloneElement(children, {
          setTripId: setTripId,
          setLocationId: setLocationId,
          tripId: tripId,
          locationId: locationId,
          locationName: locationName,
          trip: trip && trip.data,
          location: location && location.data,
          //weather: weather && weather.data,
          translations: settings.data.translations,
          settings: settings.data.global,
          activities: activities,
          setActivities: setActivities,
        })}
      </main>
    </div>
  );
}
