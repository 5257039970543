import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './Header.scss';

import { getLogo } from 'helpers/marketHelper';
import { getLocalStorage } from 'helpers/uiHelper';
import { NOTIFICATION_ACCEPTED } from 'shared/constants';
import MainNavigation from 'components/mainNavigation/MainNavigation';
import { Link } from 'react-router-dom';

function Header({
  title,
  basePath,
  isSummer,
  translations,
  tripId,
  locationId,
  locationName,
  trip,
  notifications
}) {
  const logo = getLogo();
  const [noticeAmount, setNoticeAmount] = useState(0);
  const routerLocation = useLocation();
  const routerList = routerLocation.pathname.split('/');
  const lastLevelPath = routerList[routerList.length - 1];

  const fetchAccepted = setInterval(() => {
    const acceptedNotifications = getLocalStorage(NOTIFICATION_ACCEPTED) || '';
    let accepted = 0;
    if (acceptedNotifications) {
      accepted = acceptedNotifications.split(';').filter(x => x).length;
    }
      const all = notifications &&
      notifications.notifications.length;
      setNoticeAmount(all - accepted);
  }, 1500);


  useEffect(() => {

    return () => {
      window.clearInterval(fetchAccepted);
    }
  });

  return (
    <header className="header-container">
      <nav className="header-content">
        <div className="header-main">
          <img src={logo} className="header-logo" alt="logo" />

          <MainNavigation
            basePath={basePath}
            isSummer={isSummer}
            translations={translations && translations.global}
            tripId={String(tripId)}
            locationId={String(locationId)}
            locationName={String(locationName)}
            trip={trip}
          />
        </div>

        <div className="header-title">{title}</div>
        <div className="header-notifications">
          <Link
            to={`/trip/${tripId}/notifications`}
          >
            <i className={`header-notification-bell ${lastLevelPath === 'notifications' ? 'header-notification-bell--active' : ''}`}></i>
            {!!noticeAmount && noticeAmount > 0 && <span className="header-amount">{noticeAmount}</span>}
            </Link>
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  basePath: PropTypes.string,
  isSummer: PropTypes.bool,
  translations: PropTypes.object,
  tripId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  locationId: PropTypes.string,
};

export default Header;
