import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Lightbox from 'react-image-lightbox';

import './MapImage.scss';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

function MapImage({ image, isSmall }) {
  const [isOpen, setOpen] = useState(false);

  if (!image) {
    return null;
  }

  // TODO: Need to test this on a real phone to move forward.

  return (
    <div
      className={`mapimage__list ${isSmall ? 'is-small' : ''}`}
      style={{ backgroundImage: `url(${image})` }}
    >
      {!isOpen && (
        <div className="mapimage__trigger" onClick={() => setOpen(true)}>
          <div className="app-content">
            <div className="mapimage__trigger-icon"></div>
          </div>
        </div>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={image} // Required
          onCloseRequest={() => setOpen(false)} // Required
          clickOutsideToClose={false}
          enableZoom={true}
        />
      )}
    </div>
  );
}

MapImage.propTypes = {
  image: PropTypes.string,
  isSmall: PropTypes.bool,
};

export default MapImage;
