import React, { useLayoutEffect } from 'react';
import { PropTypes } from 'prop-types';

import './LocationWebcams.scss';
import { Link } from 'react-router-dom';

function LocationWebcams({ webcams, htmlWebcams, translations }) {
  const className = "webcams";

  useLayoutEffect(() => {
    if (htmlWebcams) {
      const webcamList = document.querySelectorAll(`.${className}__htmlWrapper`);
      webcamList.forEach(webcam => {
        const scriptsTags = webcam.getElementsByTagName(
            "script"
        );

        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (let i = 0; i < scriptsTags.length; i++) {
          const parentNode = scriptsTags[i].parentNode;
          if (parentNode) {
            const newScriptTag = document.createElement("script");
            newScriptTag.type = "text/javascript";
            if (scriptsTags[i].text) { newScriptTag.innerHTML = scriptsTags[i].text; };
            if (scriptsTags[i].src) { newScriptTag.src = scriptsTags[i].src; }
            parentNode.removeChild(scriptsTags[i]);
            parentNode.appendChild(newScriptTag);
          }
        }
      });
    }
  }, [htmlWebcams]);

  return (
    <div className={className}>
      {webcams && webcams.filter(webcam =>
        (webcam.webCamType === 'OnTheSnow' ||
          webcam.webCamType === 'Fertel') &&
        webcam.imageUrl)
        .map((webcam, idx) =>
          <div className={`${className}__imageWrapper`} key={`w_${idx}`}>
            <Link
              to={`${window.location.pathname}/${idx}`}
              className={`${className}__imageButton`}
            >
              <img
                className={`${className}__image ${className}__image--${webcam.webCamType}`}
                src={webcam.imageUrl}
                alt={webcam.name}
              />
              {webcam.webCamType === 'OnTheSnow' &&
                <div className={`${className}__imageTitle`}>{webcam.name}</div>
              }
            </Link>
          </div>)}
          {htmlWebcams && htmlWebcams.map((webcam, idx) => (
            <div
              className={`${className}__htmlWrapper`}
              dangerouslySetInnerHTML={{ __html: webcam }}
              key={`hw_${idx}`}
            />
          ))}
    </div>
  );
}

LocationWebcams.propTypes = {
  webcams: PropTypes.arrayOf(PropTypes.object),
  htmlWebcams: PropTypes.arrayOf(PropTypes.string),
  translations: PropTypes.object,
};

export default LocationWebcams;
